import React, { useState, createContext, useContext } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";
export const BillContext = createContext();
const BillProvider = ({ children }) => {
    const [billData, setBillData] = useState([]);
    const { authenticToken } = useContext(AuthContext)

    const Bill_TABLE = ["Amount Recive", 'date', 'Cheque No', "Bill ID", "Invoice Id"];
    const BillAPI = `${api}/bills`
    const BillAdded = async () => {
        try {
            const response = await fetch(BillAPI, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setBillData(data);
        } catch (err) {
            console.log('An Error Occurred at bill provider:', err);
        }
    }

    const BillState = {
        billData,
        setBillData,
        BillAdded,
        Bill_TABLE,
        BillAPI
    }

    return (
        <BillContext.Provider value={BillState}>
            {children}
        </BillContext.Provider>
    )
}
export { BillProvider };