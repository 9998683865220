import React, { createContext, useContext, useState } from "react";
import { api } from "../../API's";
import { AuthContext } from "../AuthProvider";
import moment from "moment";

export const DashBoardContext = createContext();
const DashBoardProvider = ({ children }) => {
    const { authenticToken } = useContext(AuthContext)
    const [dashboardData, setDashBoardData] = useState([])
    const dashboardAPI = `${api}/dashboard`;
    const [datepick, setDate] = useState({
        startDate: null,
        endDate: null
    });
    const DashBoardAdded = async () => {
        if (!authenticToken) {
            console.log("token not found");
        }
        const formattedStartDate = datepick.startDate ? moment(datepick.startDate).format('MM/DD/YYYY, h:mm:ss A') : null;
        const formattedEndDate = datepick.endDate ? moment(datepick.endDate).format('MM/DD/YYYY, h:mm:ss A') : null;

        try {
            const response = await fetch(`${dashboardAPI}?start_date=${formattedStartDate}&end_date=${formattedEndDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setDashBoardData(data)
        } catch (err) {
            console.log('An Error Occurred at Dashboard provider:', err);
        }
    };
    const authState = {
        DashBoardAdded,
        dashboardData,
        datepick,
        setDate
    }
    return (
        <DashBoardContext.Provider value={authState}>
            {children}
        </DashBoardContext.Provider>
    )
}
export { DashBoardProvider };