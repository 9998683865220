import React from 'react'

const Popup = ({ message, LogOut, setShowPopup }) => {
    return (
        <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <svg className="mx-auto my-6 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    <div className="p-4 md:p-5 text-center">
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{message}</h3>
                        <button onClick={() => { setShowPopup(false); LogOut() }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-8 mr-2 py-2.5 text-center">
                            Yes
                        </button>
                        <button onClick={() => setShowPopup(false)} type="button" className="text-gray-600 bg-white hover:bg-green-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Popup