import React, { useState, createContext, useContext } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";


export const OrderContext = createContext();
const OrderProvider = ({ children }) => {
    const { authenticToken } = useContext(AuthContext)
    const [truckFilter, setTruckFilter] = useState('')
    const [monthFilter, setMonthFilter] = useState('')
    const [statusFilter, setStatusFilter] = useState('')
    const [companyFilter, setCompanyFilter] = useState('')
    const [currentOrderPage, setCurrentOrderPage] = useState(1)
    const [orderTotalPages, setOrderTotalPages] = useState(0);
    const [orderAllData, setOrderAllData] = useState([]);
    const [orderPaginateData, setOrderPaginateData] = useState([]);
    const OrderAPI = `${api}/orders`

    const OrderAll = async () => {
        try {
            const response = await fetch(OrderAPI, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setOrderAllData(data);
        } catch (err) {
            console.log('An Error Occurred at Order provider:', err);
        }
    }

    const OrderPaginate = async () => {
        try {
            const response = await fetch(`${OrderAPI}/paginate?page=${currentOrderPage}&company=${companyFilter}&truck=${truckFilter}&month=${monthFilter}&status=${statusFilter}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setOrderTotalPages(data.totalPages)
            setOrderPaginateData(data.data);
        } catch (err) {
            console.log('An Error Occurred at Order provider:', err);
        }
    }
    const OrderState = {
        orderAllData,
        OrderAll,
        OrderPaginate,
        orderPaginateData,
        OrderAPI,
        currentOrderPage,
        setCurrentOrderPage,
        orderTotalPages,
        truckFilter,
        setTruckFilter,
        monthFilter,
        setMonthFilter,
        statusFilter,
        setStatusFilter,
        companyFilter,
        setCompanyFilter
    }


    return (
        <OrderContext.Provider value={OrderState}>
            {children}
        </OrderContext.Provider>
    )
}
export { OrderProvider };