import React, { useContext, useEffect, useRef, useState } from "react";
import { CompanyContext } from "../Contexts/CompanyProvider";
import { TruckContext } from "../Contexts/TruckProvider";
import { OrderContext } from "../Contexts/OrderProvider";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";
import { InvoiceOrderContext } from "../Contexts/InvoiceOrderProvider";
import moment from "moment";
import Autocomplete from "./AutoComplete";
import { AuthContext } from "../Contexts/AuthProvider";

const InvoiceForm = () => {
    const { companyData, CompanyAllData, CompanyAPI } = useContext(CompanyContext)
    const { authenticToken } = useContext(AuthContext)
    const { truckData, TruckAllData } = useContext(TruckContext)
    const { orderAllData, OrderAll, OrderAPI } = useContext(OrderContext)
    const { InvoiceAllData, InvoiceAPI } = useContext(InvoiceOrderContext)
    const [companyInvoice, setCompanyInvoice] = useState('')
    const [truckInvoice, setTruckInvoice] = useState('')
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [getInvoiceId, setInvoiceId] = useState('')
    const [showTable, setShowTable] = useState(false)
    const [orderInvoice, setOrderInvoice] = useState([])
    const [companyDataInvoice, setCompanyDataInvoice] = useState({})
    const [invoiceData, setInvoiceData] = useState([])
    const [orderIds, setOrderIds] = useState([])
    const [totalPrice, setTotalPrice] = useState(0);
    const [invoiceRemaingAmount, setInvoiceRemaingAmount] = useState(0);
    const [invoiceFound, setInvoiceFound] = useState(true)
    const [isloading, setIsLoading] = useState(false)
    const [companyError, setCompanyError] = useState('')
    const [truckError, setTruckError] = useState('')
    const navigate = useNavigate()
    const invoiceRef = useRef(null);
    const [popup, setPopup] = useState(false)
    useEffect(() => {
        CompanyAllData()
        TruckAllData()
        InvoiceAllData()
        OrderAll()
    }, [])
    const handlePdf = () => {
        const input = document.getElementById('page');
        input.style.display = 'block'; // Show the hidden page
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4');
                var width = pdf.internal.pageSize.getWidth();
                // var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, width, 0);
                pdf.save("Invoice.pdf");
                input.style.display = 'none';
            });
        input.style.display = 'none';
        handleSaveToLocal()
        setPopup(true)
    };
    const handleSaveToLocal = async () => {
        setIsLoading(true)
        orderIds?.forEach(async (element) => {
            try {
                await fetch(`${OrderAPI}/${element.id}`, {
                    method: 'Put',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authenticToken}`
                    },
                    body: JSON.stringify({ invoiceCreated: 1 })
                });
            } catch (error) {
                console.error('Error in updating orders:', error);
            }
        })
        const companyItem = companyData?.find((item) => companyInvoice === item.name);
        const depthTotal = companyItem.depth + invoiceData.total
        try {
            if (companyItem) {
                await fetch(`${CompanyAPI}/${companyItem._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authenticToken}`
                    },
                    body: JSON.stringify({ depth: depthTotal })
                });
            } else {
                console.error('Company not found.');
            }
        } catch (error) {
            console.error('Error in updating orders:', error);
        }
        try {
            await fetch(InvoiceAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
                body: JSON.stringify(invoiceData),
            })
        } catch (error) {
            console.log('An error occurred in Invoice Post!', error);
        }
        setIsLoading(false)
        setPopup(true)
        InvoiceAllData()
        OrderAll()
        CompanyAllData()
    }
    const ifDateEmpty = () => {
        if (dateFrom === '') {
            const firstdate = moment().startOf('month').format('YYYY-MM-DD');
            setDateFrom(firstdate)
        }
        if (dateTo === '') {
            const lastdate = moment().endOf('month').format('YYYY-MM-DD');
            setDateTo(lastdate)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const dateFromMinusOne = new Date(dateFrom);
        dateFromMinusOne.setDate(dateFromMinusOne.getDate() - 1);
        const companyD = companyData?.find((c) => c.name === companyInvoice)
        const truckD = truckInvoice === "" ? '' : truckData?.find((t) => t.truckNumber === truckInvoice)
        if (companyD) {
            if (truckInvoice === '' || truckD) {
                const companyID = companyD._id
                const truckID = truckD._id
                const OrdersMatchCompnay = orderAllData?.filter(item =>
                    item.company === companyID &&
                    (truckInvoice !== "" ? item.truck == truckID : true) &&
                    ((dateFromMinusOne) <= (new Date(item.date.split(",")[0])) && (new Date(item.date.split(",")[0])) <= (new Date(dateTo))) &&
                    item.invoiceCreated !== 1
                )
                let trucks = [];

                OrdersMatchCompnay.forEach(element => {
                    // Check if the truck id already exists in the trucks array
                    if (!trucks.some(truck => truck.id === element.truck)) {
                        trucks.push({
                            "id": element.truck
                        });
                    }
                });

                if (OrdersMatchCompnay.length !== 0) {
                    const remaings = companyData?.filter((item) =>
                        companyInvoice === item.name)
                    const companyDepth = remaings[0].depth;
                    setInvoiceRemaingAmount(companyDepth)
                    var orderIDs = [];
                    OrdersMatchCompnay.forEach(element => {
                        orderIDs.push({
                            "id": element._id
                        })
                    })
                    setOrderIds(orderIDs)
                    const invoiceId = Date.now()
                    const orderfiltter = OrdersMatchCompnay.filter((item) =>
                        item.company === companyID)
                    var total = orderfiltter.reduce((accum, item) => accum + parseInt(item.price), 0)
                    setTotalPrice(total);
                    const invoice = {
                        invoiceNo: "I-" + invoiceId,
                        truckInvoiceName: trucks,
                        companyInvoiceName: companyID,
                        ordersArray: orderIDs,
                        total: total,
                        remaining_amount: total,
                        dateFrom: dateFrom,
                        dateTo: dateTo,
                        dateOfInvoice: new Date(Date.now()).toLocaleString(),
                    }
                    setInvoiceId('I-' + invoiceId)
                    setInvoiceData(invoice)
                    setCompanyDataInvoice(companyD)
                    setOrderInvoice(orderfiltter);
                    setInvoiceFound(true);
                    setTimeout(() => {
                        invoiceRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }, 300);
                } else {
                    setInvoiceFound(false)
                }
                setShowTable(true)
            } else {
                setTruckError("Truck Not Registered")
            }
        } else {
            setCompanyError("Company Not Registered")
        }
    }
    return (<>
        <div className="m-auto w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
            <h5 className="text-2xl mb-10 font-medium text-gray-900 dark:text-white">Invoice Form</h5>
            <form onSubmit={handleSubmit} className="">
                <div className="sm:grid m-auto w-64 md:w-96 mb-0 mt-4">
                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Company
                    </label>
                    <Autocomplete
                        optionsSelected={(p) => setCompanyInvoice(p)}
                        options={companyData.map((c) => c.name)}
                        valueEdit=''
                        isRequired={true}
                        placeHolder={"Enter Company Name"}
                    />
                    {companyError && <div className="text-red-500 text-sm">{companyError}</div>}

                </div>
                <div className=" sm:grid m-auto w-64 md:w-96 mt-4">
                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Date (From)
                    </label>
                    <input
                        type="date"
                        value={dateFrom}
                        onChange={(e) => setDateFrom(e.target.value)}
                        className={`bg-gray-50 border border-gray-400 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                        placeholder="24/2/2023"
                    />
                </div>
                <div className="sm:grid w-64 m-auto md:w-96 mt-4">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Date (To)
                    </label>
                    <input
                        type="date"
                        value={dateTo}
                        onChange={(e) => setDateTo(e.target.value)}
                        placeholder="24/2/2023"
                        className={`bg-gray-50 border border-gray-400 text-black-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                    />
                </div>
                <div className="sm:grid w-64 m-auto md:w-96 mt-4">
                    <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Truck
                    </label>
                    <Autocomplete
                        optionsSelected={(p) => setTruckInvoice(p)}
                        options={truckData.map((c) => c.truckNumber)}
                        valueEdit=''
                        isRequired={false}
                        placeHolder={"Enter Truck Name"}
                    />
                    {truckError && <div className="text-red-500 text-sm">{truckError}</div>}
                </div>
                <div className="mt-5 w-64 m-auto md:w-96">
                    <button
                        onClick={() => ifDateEmpty()}
                        type="submit"
                        className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        Submit
                    </button>
                </div>
            </form>
        </div>
        {
            showTable ?
                <div ref={invoiceRef} className="m-auto w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    {
                        invoiceFound ?
                            <div>
                                <label className="text-3xl">Do you Want to Save this Invoice?</label><br />
                                {isloading ?
                                    <div
                                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status">
                                        <span
                                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                        >Loading...</span
                                        >
                                    </div>
                                    :
                                    <div className="mb-5 mt-2">
                                        <button onClick={() => { handlePdf(); }} className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" >Download and Save</button><br />
                                        <button onClick={() => handleSaveToLocal()} className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Save only</button>
                                        <button onClick={() => setShowTable(false)} className='ml-2 btn_top m-auto w-30 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:hover:text-white-700 dark:focus:ring-red-800'>Delete</button>
                                    </div>
                                }
                                <section className="py-5 md:py-20 bg-black">
                                    <div className="max-w-5xl mx-auto lg:py-16 md:py-16 py-0 bg-white">
                                        <div className="invoiceContainer2">
                                            <div className="flex justify-center">
                                                <div className="space-y-3 text-slate-700">
                                                    <p className="md:text-5xl sm:text-sm font-extrabold tracking-tight uppercase font-body">
                                                        Invoice
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="md:ml-10 sm:ml-3 flex space-x-8 font-semibold  lg:text-lg font_size  md:mt-20 md:mb-20 mt-2 mb-2 " >
                                                <ul className="text-left font-bold" >
                                                    <li>Corporation Name:</li>
                                                    <li>Email Address:</li>
                                                    <li>Contact:</li>
                                                    <li>Invoice Duration:</li>
                                                    <li>Invoice NO:</li>
                                                </ul>
                                                {
                                                    <ul key={companyDataInvoice?._id} className="text-left">
                                                        <li>{companyDataInvoice?.name}</li>
                                                        <li>{companyDataInvoice?.email || "-"}</li>
                                                        <li>{companyDataInvoice?.contact || "-"}</li>
                                                        <li>{dateFrom} - {dateTo}</li>
                                                        <li>{getInvoiceId}</li>
                                                    </ul>
                                                }
                                            </div>
                                            <table className="rwd-table w-full font_size">
                                                <tbody>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Truck No./Name</th>
                                                        <th>From(City)</th>
                                                        <th>To(City)</th>
                                                        <th>Note</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                    {
                                                        orderInvoice && orderInvoice.map((item) =>
                                                            <tr key={item._id}>
                                                                <td data-th="Order No">
                                                                    {item.date}
                                                                </td>
                                                                <td data-th="Truck No">
                                                                    {truckData?.find((t) => t._id === item.truck).truckNumber}
                                                                </td>
                                                                <td data-th="From (City)">
                                                                    {item.from}
                                                                </td>
                                                                <td data-th="To (City)">
                                                                    {item.to}
                                                                </td><td data-th="Note">
                                                                    {item.describtion || 'No Notes'}
                                                                </td>
                                                                <td data-th="Amount">
                                                                    Rs. {item.price}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>
                                                            Subtotal
                                                        </th>
                                                        <td data-th="SubTotal">
                                                            Rs. {totalPrice}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th >
                                                            Remaining
                                                        </th>
                                                        <td data-th="Remaining">
                                                            Rs. {invoiceRemaingAmount}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>
                                                            Total
                                                        </th>
                                                        <td data-th="Grand Total">
                                                            Rs. {totalPrice + invoiceRemaingAmount}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            :
                            <div tabIndex="-1" className="h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                                <div className="relative p-4 w-full max-w-md max-h-full">
                                    <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                                        <div className="p-4 md:p-5 text-center">
                                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                            </svg>
                                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Orders Not Found For Invoice</h3>
                                            <button onClick={() => { setShowTable(false); }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                </div >
                :
                ''
        }
        <div id="page" className="hidden pl-2 mx-auto py-16  bg-white">
            <div className="invoiceContainer2 space-y-32 ">
                <div className="flex justify-center">
                    <div className="space-y-3 text-slate-700">
                        <p className="text-5xl font-extrabold tracking-tight uppercase font-body">
                            Invoice
                        </p>
                    </div>
                </div>
                <div className="ml-10  flex space-x-8 font-semibold text-lg mt-20 mb-20 " >
                    <ul className="text-left font-bold" >
                        <li>Corporation Name:</li>
                        <li>Email Address:</li>
                        <li>Contact:</li>
                        <li>Invoice Duration:</li>
                        <li>Invoice NO:</li>
                    </ul>
                    {
                        <ul className="text-left">
                            <li>{companyDataInvoice.name}</li>
                            <li>{companyDataInvoice.email || "-"}</li>
                            <li>{companyDataInvoice.contact || "-"}</li>
                            <li>{dateFrom} - {dateTo}</li>
                            <li>{getInvoiceId}</li>
                        </ul>
                    }
                </div>
                <div className="rounded-lg relative  shadow-md sm:rounded-lg">
                    <table className="rounded-lg w-full text-md text-left rtl:text-right text-gray-700 dark:text-gray-400">
                        <thead style={{ background: ' #428bca' }} className="rounded-lg text-xs  text-white dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Truck No./ Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    From (City)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    To (City)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Note
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                orderInvoice && orderInvoice.map((item) =>
                                    <tr key={item._id} className="border-y-2">
                                        <td scope="col" className="bg-white px-6 py-3">
                                            {item.date}
                                        </td>
                                        <td scope="col" className=" bg-white px-6 py-3">
                                            {truckData.find((t) => t._id === item.truck).truckNumber}
                                        </td>
                                        <td scope="col" className="bg-white px-6 py-3">
                                            {item.from}
                                        </td>
                                        <td scope="col" className="bg-white px-6 py-3">
                                            {item.to}
                                        </td>
                                        <td scope="col" className="bg-white px-6 py-3">
                                            {item.describtion || "NO Notes"}
                                        </td>
                                        <td scope="col" className="bg-white px-6 py-3">
                                            Rs. <span className='font-medium'>{item.price}</span>
                                        </td>
                                    </tr>
                                )}
                        </tbody>
                        <tfoot>
                            <tr style={{ background: ' #428bca' }} className=" dark:bg-gray-800 text-white dark:hover:bg-gray-600">
                                <th className="px-6 py-4 ">
                                </th>
                                <td className="px-6 py-4">
                                </td>
                                <td className="px-6 py-4">

                                </td><td className="px-6 py-4">

                                </td>
                                <td className="px-6 py-4 font-medium">
                                    SubTotal
                                </td>
                                <th className="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white">
                                    Rs. {totalPrice}
                                </th>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th className="px-6 py-4 ">
                                </th>
                                <td className="px-6 py-4">

                                </td>
                                <td className="px-6 py-4">

                                </td>
                                <td className="px-6 py-4">
                                </td>
                                <td className="px-6 py-4 font-medium">
                                    Remaining
                                </td>
                                <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Rs. {invoiceRemaingAmount}
                                </th>
                            </tr>
                            <tr className="bg-cyan-50 dark:bg-gray-800 border-y-2 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                </th>
                                <td className="px-6 py-4">

                                </td>
                                <td className="px-6 py-4">

                                </td>
                                <td className="px-6 py-4">
                                </td>
                                <td className="px-6 py-4 font-medium">
                                    GrandTotal
                                </td>
                                <th className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Rs. {totalPrice + invoiceRemaingAmount}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

        </div>

        {popup &&
            <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="p-4 md:p-5 text-center">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Invoice Created successfully</h3>
                            <button onClick={() => { setPopup(false); navigate(-1) }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    );
};

export default InvoiceForm;
