import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './Login';
import Registration from './Registration';
import TruckBasicInfo from '../Components/TruckBasicInfo';
import CompanyForm from '../Components/CompanyForm';
import OrdersTable from '../Components/OrdersTable';
import InvoiceTable from '../Components/InvoiceTable';
import OrderForm from '../Components/OrderForm';
import InvoiceForm from '../Components/InvoiceForm';
import CompanyTable from '../Components/CompanyTable';
import TruckTable from '../Components/TruckTable';
import DashBoard from '../Components/DashBoard';
import Home from '../Components/Home';
import UserTable from '../Components/UserTable';
import PageNotFound from '../Components/PageNotFound';
import { AuthContext } from '../Contexts/AuthProvider';

const AuthenticRoutes = ({ children }) => {
    const { logginStatus, checkForSessionValues } = useContext(AuthContext);
    useEffect(() => {
        checkForSessionValues();
    }, []);
    return logginStatus ? (
        <Routes>
            <Route path='/' element={<Home />}>
                <Route
                    path="*"
                    element={()=>{return "404"}}
                />
                <Route path='/' element={<DashBoard />} />
                <Route path='registerationform' element={<Registration />} />
                <Route path='truckform' element={<TruckBasicInfo />} />
                <Route path='companyform' element={<CompanyForm />} />
                <Route path='ordertable' element={<OrdersTable />} />
                <Route path='invoicetable' element={<InvoiceTable />} />
                <Route path='orderform' element={<OrderForm />} />
                <Route path='invoiceform' element={<InvoiceForm />} />
                <Route path='companytable' element={<CompanyTable />} />
                <Route path='usertable' element={<UserTable />} />
                <Route path='trucktable' element={<TruckTable />} />
                {children}
            </Route>
        </Routes>
    ) : (
        <Routes>
            <Route path='/' element={<Home />}>
                <Route path='/' element={<Login />} />
                <Route
                    path="*"
                    element={<PageNotFound />}
                />
            </Route>
        </Routes>
    );
};
export default AuthenticRoutes;