import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../Contexts/CompanyProvider';
import { InvoiceOrderContext } from '../Contexts/InvoiceOrderProvider';
import { AuthContext } from '../Contexts/AuthProvider';

const BillTable = ({ setShowBillTable, billItem, Bill_TABLE, BillAPI }) => {
    const { InvoicePaginate, InvoiceAPI } = useContext(InvoiceOrderContext)
    const { CompanyAPI } = useContext(CompanyContext)
    const { authenticToken } = useContext(AuthContext)
    const [showForm, setShowForm] = useState(false)
    const [invoiceObj, setInvoiceObj] = useState(billItem)
    const [billArray, setBillArray] = useState(invoiceObj.bills)
    const [companyObj, setCompanyObj] = useState(invoiceObj.companyInvoiceName)
    const [amountError, setAmountError] = useState('')
    const [loading, setLoading] = useState(false)
    const [popup, setPopup] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [item, setItem] = useState({})
    const [payment, setPayment] = useState('')
    const [submitMessage, setSubmitMessage] = useState('')
    const [amountRecived, setAmountRecived] = useState('')
    const [chequeNo, setChequeNo] = useState('')
    useEffect(() => {
        setAmountRecived(item.amountRecived)
        setPayment(item.paymentMethod == '' ? "1" : "2")
        setChequeNo(item.paymentMethod)
    }, [showForm])
    const handleDeleteBill = async (item) => {
        setDeleteLoading(true)
        try {
            await fetch(`${CompanyAPI}/${companyObj._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
                body: JSON.stringify({ depth: item.amountRecived + companyObj.depth })
            });

            await fetch(`${BillAPI}/${item._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
            });
            const invoiceUpdate = {
                remaining_amount: parseInt(item.amountRecived) + parseInt(invoiceObj.remaining_amount),
                invoicePaid: 0
            }
            await fetch(`${InvoiceAPI}/${item.invoiceId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
                body: JSON.stringify(invoiceUpdate)
            });
        } catch (error) {
            console.error('Error in invoice updating company depth', error);
        }
        InvoicePaginate()
        const updatedInvoiceObj = {
            ...invoiceObj,
            remaining_amount: parseInt(item.amountRecived) + parseInt(invoiceObj.remaining_amount),
            invoicePaid: 0
        };
        setInvoiceObj(updatedInvoiceObj)
        setBillArray(billArray?.filter((i) => i._id !== item._id))
        const updatedCompanyObj = {
            ...companyObj,
            depth: parseInt(companyObj.depth) + parseInt(item.amountRecived)
        };
        setCompanyObj(updatedCompanyObj);
        setDeleteLoading(false)
    }
    const formSubmit = async (e) => {
        e.preventDefault()
        if (parseInt(amountRecived) > 0) {
            if (parseInt(amountRecived) <= parseInt(invoiceObj.remaining_amount) + parseInt(item.amountRecived)) {
                setLoading(true)
                try {
                    if (companyObj) {
                        await fetch(`${CompanyAPI}/${companyObj._id}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authenticToken}`
                            },
                            body: JSON.stringify({ depth: (parseInt(companyObj.depth) + parseInt(item.amountRecived) - parseInt(amountRecived)) })
                        });
                    } else {
                        console.error('Company not found.');
                    }
                } catch (error) {
                    console.error('Error in invoice updating company depth', error);
                }
                const bill = {
                    amountRecived: parseInt(amountRecived),
                    paymentMethod: payment == 2 ? chequeNo : ''
                }
                try {
                    await fetch(`${BillAPI}/${item._id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authenticToken}`
                        },
                        body: JSON.stringify(bill)
                    });
                } catch (error) {
                    console.log('An error occurred in bill edit!', error);
                }
                const invoiceUpdate = (parseInt(invoiceObj.remaining_amount) + parseInt(item.amountRecived) - parseInt(amountRecived))
                const invoiceItem = {
                    remaining_amount: invoiceUpdate,
                    invoicePaid: invoiceUpdate === 0 ? 1 : 0
                }
                try {
                    await fetch(`${InvoiceAPI}/${invoiceObj._id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authenticToken}`
                        },
                        body: JSON.stringify(invoiceItem)
                    });
                } catch (error) {
                    console.log('An error occurred in updating invoice!', error);
                }
                const updatedBillArray = billArray.map(billItem =>
                    billItem._id === item._id
                        ? { ...billItem, amountRecived: parseInt(amountRecived) }
                        : billItem
                );
                const updatedInvoiceObj = {
                    ...invoiceObj,
                    remaining_amount: invoiceUpdate,
                    invoicePaid: invoiceUpdate === 0 ? 1 : 0
                };
                setInvoiceObj(updatedInvoiceObj)
                setBillArray(updatedBillArray)
                const updatedCompanyObj = {
                    ...companyObj,
                    depth: (parseInt(companyObj.depth) + parseInt(item.amountRecived) - parseInt(amountRecived))
                };
                setCompanyObj(updatedCompanyObj)
                setShowForm(false)
                setLoading(false)
                setAmountError('')
                InvoicePaginate()
            } else {
                setAmountError('Amount Should be lesser than Remaining depths')
            }
        }
    }
    return (
        <>

            <div tabIndex="-1" className="h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                <div className="relative p-4 w-full md:max-w-full max-w-md max-h-full">
                    <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className=" md:p-2 text-center">
                            {billArray.length !== 0 ?
                                <div className="flex flex-col w-full">
                                    <p className='mt-2 text-2xl font-semibold'>Bills Table</p>
                                    <div className="overflow-x-auto w-full sm:-mx-6 lg:-mx-8" style={{ margin: 'auto' }}>
                                        <div className="inline-block min-w-full py-2">
                                            <div className="overflow-y-scroll max-h-72">
                                                <table className="min-w-full text-left text-sm font-light">
                                                    <thead className="border-b font-medium dark:border-neutral-500">
                                                        <tr>
                                                            {Bill_TABLE.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((head) => (
                                                                <th key={head} scope="col" className="px-2 py-4 min-w-28">{head}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {billArray?.map((item) => {
                                                            return (
                                                                <tr key={item._id} className="border-b dark:border-neutral-500">
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium ">{item.amountRecived || "Not Recorded"}</td>
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium">{item.date || "Not Recorded"}</td>
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium">{item.paymentMethod || "Cash payment"}</td>
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium">{item.billNo || 'not recorded'}</td>
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium"> {item.invoiceNo}</td>
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium cursor-pointer" onClick={() => { setItem(item); setShowForm(true); }}> Edit</td>
                                                                    <td className="whitespace-nowrap px-2 py-4 font-medium cursor-pointer" onClick={() => {
                                                                        setItem(item); setPopup(true);
                                                                        setSubmitMessage("Are you sure you want to delete this Bill?")
                                                                    }}> Delete</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <button onClick={() => setShowBillTable(false)} className=' w-40 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>Back</button>
                                    </div>
                                </div> :
                                <div className='text-blue-gray-500 md:text-2xl text-sm  space-y-6 px-2 py-2'>
                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <p>There is no bill for this invoice</p>
                                    <button onClick={() => setShowBillTable(false)} className='py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>Back</button>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            {
                showForm ?
                    <div className='overlay'>
                        <div className="modal w-72">
                            <form className="space-y-6 " onSubmit={formSubmit}>
                                <h5 className="text-xl font-medium text-gray-900 dark:text-white">Enter Amount</h5>
                                <div>
                                    <div className='float-left text-sm'>Company Depth Amount:</div><span className='float-right text-sm mr-2'>Rs. {companyObj.depth}</span><br />
                                    <div className='float-left text-sm'>Current Invoice Amount:</div><span className='float-right text-sm mr-2'>Rs.{invoiceObj.total}</span><br />
                                    <div className='float-left text-sm'>Payable Amount:</div><span className='float-right text-sm mr-2'>Rs.{invoiceObj.remaining_amount}</span>
                                </div>
                                <FormControl sx={{ m: 0, minWidth: 250, mb: 2 }} size="small">
                                    <InputLabel id="demo-select-small-label">Payment Method</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={payment}
                                        onChange={(e) => setPayment(e.target.value)}
                                        label="Payment Method"
                                    >
                                        <MenuItem value='1' selected>
                                            Cash
                                        </MenuItem>
                                        <MenuItem value='2'>
                                            Cheque
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <div>
                                    <label>Amount Recieve</label>
                                    <input
                                        type="number"
                                        id=""
                                        onChange={(e) => setAmountRecived(e.target.value)}
                                        value={amountRecived}
                                        className={`mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                        placeholder="900"
                                    />
                                    {amountError && <div className="text-red-500 text-sm">{amountError}</div>}
                                    {payment == 2 ?
                                        <>
                                            <label>Cheque Number</label>
                                            <input
                                                type="text"
                                                id=""
                                                onChange={(e) => setChequeNo(e.target.value)}
                                                value={chequeNo}
                                                className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                placeholder="***********"
                                                required
                                            />
                                        </>
                                        :
                                        ''}
                                </div>
                                {
                                    loading ?
                                        <button disabled type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                            </svg>
                                            Loading...
                                        </button>
                                        :
                                        <div>
                                            <button type='submit'
                                                className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                            >
                                                Save
                                            </button>
                                            <button type='button'
                                                onClick={() => { setShowForm(false); setChequeNo(''); setAmountRecived(''); setPayment('1'); setAmountError('') }}
                                                className="ml-1 bg-transparent text-gray-700 font-semibold py-2 px-2  hover:border-transparent rounded"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                }
                            </form>
                        </div>
                    </div >
                    :
                    ''
            }
            {popup &&
                <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <svg className="mx-auto  mt-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <div className="p-4 md:p-5 text-center">
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{submitMessage}</h3>

                                <div>
                                    {deleteLoading ?
                                        <button disabled type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                            </svg>
                                            Loading...
                                        </button> :
                                        <button onClick={() => { setPopup(false); handleDeleteBill(item) }} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                            Delete
                                        </button>}
                                    <button onClick={() => setPopup(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default BillTable;
