import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../Contexts/AuthProvider'
import { DashBoardContext } from '../Contexts/data_Provider/Dashboard_data'
import Charts from './Charts'
import Datepicker from 'react-tailwindcss-datepicker'


const DashBoard = () => {
    const { checkForSessionValues } = useContext(AuthContext)
    const { DashBoardAdded, dashboardData, datepick, setDate } = useContext(DashBoardContext)
    const [loadingPage, setloadingPage] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        DashBoardAdded();
    }, [datepick])
    const totalInvoices = dashboardData?.invoices || 0;
    const ordersFilter = dashboardData?.ordersFilter || 0;
    const ordersInvoiceCreatedTrue = dashboardData?.ordersInvoiceCreatedTrue || 0;
    const ordersInvoiceCreatedFalse = dashboardData?.ordersInvoiceCreatedFalse || 0;
    const paidInvoices = dashboardData?.paid_invoices || 0;
    const unpaidInvoices = totalInvoices - paidInvoices;
    const getData = async () => {
        setloadingPage(true)
        await checkForSessionValues();
        await DashBoardAdded()
        setloadingPage(false)
    }
    useEffect(() => {
        getData()
    }, [])
    const getTodayAtMidnight = () => {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    };
    const getYesterdayAtMidnight = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0); // Yesterday at 00:00:00
    };
    const getYesterdayEndOfDay = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59); // Yesterday at 23:59:59
    };
    const getLast3DaysStart = () => {
        const last3Days = new Date();
        last3Days.setDate(last3Days.getDate() - 3); // Go back 6 days (including today as day 1)
        return new Date(last3Days.getFullYear(), last3Days.getMonth(), last3Days.getDate(), 0, 0, 0); // Start at 00:00:00
    };
    const getLast7DaysStart = () => {
        const last7Days = new Date();
        last7Days.setDate(last7Days.getDate() - 7); // Go back 6 days (including today as day 1)
        return new Date(last7Days.getFullYear(), last7Days.getMonth(), last7Days.getDate(), 0, 0, 0); // Start at 00:00:00
    };
    if (loadingPage) {
        return (<h1 className='m-auto h-screen text-2xl'>loading...</h1>)
    } else {

        return (
            <div className='root'>
                <h3 className="section-head cursor-pointer" onClick={() => console.log(dashboardData)}>Overview</h3>
                <div className="analytics">
                    <div className="analytic cursor-pointer" onClick={() => navigate('usertable')}>
                        <div className="analytic-icon"><span className="las la-heart"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>

                        </span></div>
                        <div className="analytic-info">
                            <h4>Users</h4>
                            <h1>{dashboardData.users}</h1>
                        </div>
                    </div>
                    <div className="analytic cursor-pointer" onClick={() => navigate('trucktable')}>
                        <div className="analytic-icon"><span className="las la-eye"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                        </svg>
                        </span></div>
                        <div className="analytic-info">
                            <h4>Trucks</h4>
                            <h1>{dashboardData.trucks}</h1>
                        </div>
                    </div>
                    <div className="analytic cursor-pointer" onClick={() => navigate('companytable')}>
                        <div className="analytic-icon"><span className="las la-clock"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                        </svg>
                        </span></div>
                        <div className="analytic-info">
                            <h4>Companies</h4>
                            <h1>{dashboardData.companies}</h1>
                        </div>
                    </div>
                    <div className="analytic cursor-pointer" onClick={() => navigate('ordertable')}>
                        <div className="analytic-icon"><span className="las la-users"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                        </svg>
                        </span></div>
                        <div className="analytic-info">
                            <h4>Orders</h4>
                            <h1>{dashboardData.orders}</h1>
                        </div>
                    </div>
                </div>
                <div className='my-3 max-w-7xl mx-auto'>
                    <h5 className="section-head !text-sm cursor-pointer">Select Dates for Filter</h5>
                    <Datepicker
                        showShortcuts={true}
                        configs={{
                            shortcuts: {
                                Today: {
                                    text: "Today",
                                    period: {
                                        start: getTodayAtMidnight(),
                                        end: new Date() // current time as end date
                                    }
                                },
                                Yesterday: {
                                    text: "Yesterday",
                                    period: {
                                        start: getYesterdayAtMidnight(),
                                        end: getYesterdayEndOfDay()
                                    }
                                }, last3Days: {
                                    text: "Last 3 Days",
                                    period: {
                                        start: getLast3DaysStart(), // Start date 7 days ago at 00:00:00
                                        end: new Date() // End date today at 23:59:59
                                    }
                                },
                                last7Days: {
                                    text: "Last 7 Days",
                                    period: {
                                        start: getLast7DaysStart(), // Start date 7 days ago at 00:00:00
                                        end: new Date() // End date today at 23:59:59
                                    }
                                },
                                currentMonth: "This Month",
                                pastMonth: "Last Month"
                            }
                        }}
                        value={datepick}
                        onChange={newDate => setDate(newDate)}
                    />
                </div>
                <div className="flex flex-col md:flex-row justify-center items-center md:space-x-8 space-y-8 md:space-y-0 p-6 w-full max-w-6xl mx-auto">
                    <div className="bg-white shadow-xl rounded-lg p-6 flex-grow w-full md:w-1/2 min-w-0">
                        <h2 className="text-2xl font-bold mb-4 text-center">Summary<span className='text-xs'>(According to Dates)</span></h2>
                        <div className="space-y-4">
                            <div className="bg-green-100 text-green-600 p-4 rounded-lg flex justify-between items-center">
                                <span>Total Orders</span>
                                <span className="text-xl font-semibold">{ordersFilter}</span>
                            </div>
                            <div className="bg-green-100 text-green-600 p-4 rounded-lg flex justify-between items-center">
                                <span>Invoice Created Orders</span>
                                <span className="text-xl font-semibold">{ordersInvoiceCreatedTrue}</span>
                            </div>
                            <div className="bg-green-100 text-green-600 p-4 rounded-lg flex justify-between items-center">
                                <span>Invoice Not Created Orders</span>
                                <span className="text-xl font-semibold">{ordersInvoiceCreatedFalse}</span>
                            </div>
                            <div className="bg-green-100 text-green-600 p-4 rounded-lg flex justify-between items-center">
                                <span>Total Invoices</span>
                                <span className="text-xl font-semibold">{totalInvoices}</span>
                            </div>
                            <div className="bg-blue-100 text-blue-600 p-4 rounded-lg flex justify-between items-center">
                                <span>Paid Invoices</span>
                                <span className="text-xl font-semibold">{paidInvoices}</span>
                            </div>
                            <div className="bg-red-100 text-red-600 p-4 rounded-lg flex justify-between items-center">
                                <span>Unpaid Invoices</span>
                                <span className="text-xl font-semibold">{unpaidInvoices}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex-grow w-full md:w-1/2 min-w-0">
                        <Charts dashboardData={dashboardData} />
                    </div>
                </div>
            </div>
        )
    }
}

export default DashBoard