import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormControl, MenuItem, Select } from '@mui/material';
import { UsersContext } from '../Contexts/Users';
import { AuthContext } from '../Contexts/AuthProvider';
import { Pagination } from './reuseable_components/Pagination';


const UserTable = () => {
    const navigate = useNavigate()
    const UserTableHead = ["Actions", "User Name", "Email", 'Contact', 'Delete', "Edit", "block"]
    const { usersData, UsersAdded, usersAPI, setCurrentPage, currentPage, totalPages } = useContext(UsersContext)
    const { userEmail, authenticToken } = useContext(AuthContext)
    const [popups, setPopup] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [item, setItem] = useState({})
    const [blockPopup, setBlockPopup] = useState(false)
    const [loadingPage, setLoadingPage] = useState(false)
    const [popupMessage, setPopupMessage] = useState('')
    const [popupWarnig, setPopupWarning] = useState(true)
    const getData = async () => {
        setLoadingPage(true)
        await UsersAdded()
        setLoadingPage(false)
    }
    useEffect(() => { getData() }, [currentPage])

    const handleDelete = async (item) => {
        setDeleteLoading(true)
        await fetch(`${usersAPI}/${item._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authenticToken}`
            },
            body: JSON.stringify({ deleted: 1 })
        })
        await UsersAdded()
        setDeleteLoading(false)
    }
    const handleBlock = async (item) => {
        setDeleteLoading(true)
        if (item.block_user === 0) {
            await fetch(`${usersAPI}/${item._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
                body: JSON.stringify({ block_user: 1 })
            });
        } else {
            await fetch(`${usersAPI}/${item._id}`, {
                method: 'Put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
                body: JSON.stringify({ block_user: 0 })
            });
        }
        await UsersAdded()
        setDeleteLoading(false)
    }
    if (loadingPage) {
        return (<h1 className='m-auto h-screen text-2xl'>loading...</h1>)
    } else {
        return (
            <div className=''>
                <div className='flex justify-between'>
                    <div className=' text-xl md:text-3xl text-cyan-800 font-normal pl-3'>User Table</div>
                    <button onClick={() => navigate('/registerationform')} className='text_size py-1 px-1 md:py-2.5 md:px-2.5 me-2 mb-2 text-sm md:text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>Add User</button>
                </div>
                <div className="flex flex-col ml-4">
                    <div className="overflow-x-auto w-full sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light">
                                    <thead className="headTruck border-b font-medium dark:border-neutral-500">
                                        <tr>
                                            {UserTableHead.map((head) => (
                                                <th key={head} scope="col" className="text_size px-6 py-4">{head}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usersData?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).map((item) => (
                                            <tr key={item._id} className="border-b dark:border-neutral-500">
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium on-full-screen">
                                                    <FormControl size="small" sx={{ height: '10px' }}>
                                                        <Select
                                                            sx={{
                                                                height: '15px',
                                                                color: "white",
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'white',
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'white',
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'white',
                                                                }
                                                            }}
                                                            id="select-filter-by-field"
                                                        >
                                                            <MenuItem className='selectfonts' onClick={() => { navigate('/registerationform', { state: item }) }}>
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem className='selectfonts' onClick={() => {
                                                                if (item.block_user) {
                                                                    setPopup(true);
                                                                    setItem(item)
                                                                    setPopupMessage('Are you Sure You Want To UnBlock This User?')
                                                                    setBlockPopup(true)
                                                                } else {
                                                                    if (userEmail != item.email) {
                                                                        setPopup(true);
                                                                        setItem(item)
                                                                        setPopupWarning(true)
                                                                        setBlockPopup(true)
                                                                        setPopupMessage('Are you Sure You Want To Block This User?')
                                                                    } else {
                                                                        setPopup(true)
                                                                        setPopupWarning(false)
                                                                        setPopupMessage("You Can't Block user who Logged in")
                                                                    }
                                                                }
                                                            }}>
                                                                {item.block_user ?
                                                                    'UnBlock User'
                                                                    :
                                                                    'Block User'
                                                                }
                                                            </MenuItem>
                                                            <MenuItem className='selectfonts' onClick={() => {
                                                                if (userEmail != item.email) {
                                                                    setPopup(true);
                                                                    setItem(item)
                                                                    setPopupMessage('Are you Sure You Want To Delete This User?')
                                                                    setBlockPopup(false)
                                                                    setPopupWarning(true)
                                                                } else {
                                                                    setPopup(true)
                                                                    setPopupMessage("You Can't delete User Who is logged In")
                                                                    setPopupWarning(false)
                                                                }
                                                            }}>
                                                                Delete
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium"> {item.first_name + ' ' + item.last_name}</td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium">{item.email || "Not Recorded"}</td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium">{item.contact || "Not Recorded"}</td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium response" >
                                                    <button
                                                        title='Delete' onClick={() => {
                                                            if (userEmail != item.email) {
                                                                setPopup(true);
                                                                setItem(item)
                                                                setPopupMessage('Are you Sure You Want To Delete This User?')
                                                                setBlockPopup(false)
                                                                setPopupWarning(true)
                                                            } else {
                                                                setPopup(true)
                                                                setPopupMessage("You Can't delete User Who is logged In")
                                                                setPopupWarning(false)
                                                            }
                                                        }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </button>
                                                </td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium response">
                                                    <button title='Edit'
                                                        onClick={() => { navigate('/registerationform', { state: item }) }}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                        </svg>
                                                    </button>
                                                </td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium response">
                                                    {item.block_user ?
                                                        <button title='UnBlock User' onClick={() => {
                                                            setPopup(true);
                                                            setItem(item)
                                                            setPopupMessage('Are you Sure You Want To UnBlock This User?')
                                                            setBlockPopup(true)
                                                        }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 1 1 9 0v3.75M3.75 21.75h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H3.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                            </svg>
                                                        </button>
                                                        :
                                                        <button title='Block User' onClick={() => {
                                                            if (userEmail != item.email) {
                                                                setPopup(true);
                                                                setItem(item)
                                                                setPopupWarning(true)
                                                                setBlockPopup(true)
                                                                setPopupMessage('Are you Sure You Want To Block This User?')
                                                            } else {
                                                                setPopup(true)
                                                                setPopupWarning(false)
                                                                setPopupMessage("You Can't Block user who Logged in")
                                                            }
                                                        }
                                                        }>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                                                            </svg>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {totalPages > 1 ?
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages} />
                    : null}
                {
                    popups &&
                    <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="border-cyan-100 border-2 p-4 md:p-5 text-center">
                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{popupMessage}</h3>
                                    {popupWarnig ?
                                        <>
                                            {blockPopup ?
                                                <div>
                                                    {deleteLoading ?
                                                        <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                            Yes
                                                        </button> :
                                                        <button onClick={() => { handleBlock(item); setPopup(false) }} data-modal-hide="popup-modal" type="button" className="text-green-100 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                            Yes
                                                        </button>
                                                    }
                                                    <button onClick={() => setPopup(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                                                </div>
                                                :
                                                <div>
                                                    {deleteLoading ?
                                                        <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                            Delete
                                                        </button> :
                                                        <button onClick={() => { handleDelete(item); setPopup(false) }} data-modal-hide="popup-modal" type="button" className="text-green-100 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                            Delete
                                                        </button>
                                                    }
                                                    <button onClick={() => setPopup(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                                                </div>
                                            }
                                        </>
                                        :
                                        <button onClick={() => setPopup(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Back</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div >
        )
    }
}

export default UserTable