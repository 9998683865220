import React from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export function Pagination({ currentPage, setCurrentPage, totalPages }) {

  const classadded = 'text-xxs sm:text-xs md:text-base w-5 h-5 sm:w-7 md:w-10 sm:h-7 md:h-10 bg-white'
  const getItemProps = (index) => ({
    className: currentPage === index ? `${classadded} !text-white bg-black` : `${classadded}`,
    onClick: () => setCurrentPage(index),
  });

  const next = () => {
    if (currentPage === totalPages) return;

    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;

    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="text-xxs sm:text-xs md:text-base flex items-center gap-0 md:gap-8 justify-center">
      <Button
        variant="text"
        className="text-xxs sm:text-xs md:text-base flex items-center gap-2"
        onClick={prev}
        disabled={currentPage === 1}
      >
        <ArrowLeftIcon strokeWidth={1} className="h-2 w-2 md:h-4 md:w-4" /> Previous
      </Button>
      <div className="flex items-center gap-0 md:gap-2">
        {Array.from({ length: totalPages }).map((_, index) => (
          <IconButton key={index} {...getItemProps(index + 1)}>
            {index + 1}
          </IconButton>
        ))}
      </div>
      <Button
        variant="text"
        className="text-xxs sm:text-xs md:text-base flex items-center gap-2"
        onClick={next}
        disabled={currentPage === totalPages}
      >
        Next
        <ArrowRightIcon strokeWidth={2} className="h-2 w-2 md:h-4 md:w-4" />
      </Button>
    </div>
  );
}