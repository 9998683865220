import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function Charts({ dashboardData }) {
    const [options, setOptions] = useState({});

    const priceTotal = dashboardData.priceTotal || 0;
    const fuelPrice = dashboardData.fuelPriceTotal || 0;
    const labourTotal = dashboardData.labourTotal || 0;
    const profit = priceTotal - (fuelPrice || 0) - (labourTotal || 0);

    const seriesData = [fuelPrice, labourTotal, profit];
    const labels = ['Fuel Cost', 'Labour Cost', 'Profit'];
    const colors = ["#1e88e5", "#ff8f00", "#FA8072"];

    useEffect(() => {
        const newOptions = {
            chart: {
                type: 'donut',
                toolbar: {
                    show: false,
                },
            },
            labels: labels,
            colors: colors,
            legend: {
                show: true,
                position: 'bottom',
            },
            tooltip: {
                enabled: true,
                shared: false,
                custom: function ({ series, seriesIndex }) {
                    const value = series[seriesIndex];
                    const label = labels[seriesIndex];
                    const backgroundColor = colors[seriesIndex];

                    return `
                        <div class="arrow_box" style="background-color: ${backgroundColor}; color: white; padding: 10px; border-radius: 5px;">
                            <span>${label}: Rs. <b>${value}</b></span>
                        </div>`;
                },
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '60%',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: 'Total Amount',
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#32CD32',
                                fontFamily: 'Poppins, sans-serif',
                                formatter: () => `Rs. ${priceTotal}`,
                            },
                        },
                    },
                },
            },
            theme: {
                fontFamily: 'Poppins, sans-serif',
            },
        };

        setOptions(newOptions);
    }, [priceTotal, fuelPrice, labourTotal, profit]); // Recompute when these values change

    return (
        <div className="flex justify-center items-center w-full">
            <Chart
                height={430}
                width={'100%'}
                options={options}
                series={seriesData}
                type="donut"
            />
        </div>
    );
}
