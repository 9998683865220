import './App.css';
import { CompanyProvider } from './Contexts/CompanyProvider';
import { TruckProvider } from './Contexts/TruckProvider';
import { OrderProvider } from './Contexts/OrderProvider';
import { InvoiceOrderProvider } from './Contexts/InvoiceOrderProvider';
import { BillProvider } from './Contexts/BillProvider';
import PlacesProvider from './Contexts/PlacesProvider';
import { UsersProvider } from './Contexts/Users';
import { AuthProvider } from './Contexts/AuthProvider';
import AuthenticRoutes from './Authentication/AuthenticRoutes';
import { DashBoardProvider } from './Contexts/data_Provider/Dashboard_data';

function App() {
  return (
    <div className="App mb-10">
      <AuthProvider>
        <DashBoardProvider>
          <UsersProvider>
            <InvoiceOrderProvider>
              <BillProvider>
                <CompanyProvider>
                  <OrderProvider>
                    <PlacesProvider>
                      <TruckProvider>
                        <AuthenticRoutes />
                      </TruckProvider>
                    </PlacesProvider>
                  </OrderProvider>
                </CompanyProvider>
              </BillProvider>
            </InvoiceOrderProvider>
          </UsersProvider>
        </DashBoardProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
