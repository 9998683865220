import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { InvoiceOrderContext } from '../Contexts/InvoiceOrderProvider';
import { OrderContext } from '../Contexts/OrderProvider';
import { BillContext } from '../Contexts/BillProvider';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CompanyContext } from '../Contexts/CompanyProvider';
import { TruckContext } from '../Contexts/TruckProvider';
import { PlacesContext } from '../Contexts/PlacesProvider';
import BillTable from './Bill';
import { DrawerPlacement } from './Drawer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AuthContext } from '../Contexts/AuthProvider';
import { Pagination } from './reuseable_components/Pagination';


const InvoiceTable = () => {
    const {
        currentInvoicePage,
        InvoicePaginate,
        setCurrentInvoicePage,
        invoiceTotalPages,
        monthFilterInvoice,
        setMonthFilterInvoice,
        invoiceCompanyFilter,
        setInvoiceCompanyFilter,
        invoiceTruckFilter,
        setInvoiceTruckFilter,
        invoiceStatusFilter,
        setInvoiceStatusFilter,
        invoicePaginateData,
        TABLE_HEAD,
        InvoiceAPI
    } = useContext(InvoiceOrderContext)
    const { Bill_TABLE, BillAPI } = useContext(BillContext)
    const { month } = useContext(PlacesContext)
    const { authenticToken } = useContext(AuthContext)
    const { OrderAPI } = useContext(OrderContext)
    const { companyData, CompanyAllData, CompanyAPI } = useContext(CompanyContext)
    const { truckData, TruckAllData } = useContext(TruckContext)
    const navigate = useNavigate()
    const [showForm, setShowForm] = useState(false)
    const [showBillTable, setShowBillTable] = useState(false)
    const [recievingItem, setRecievingItem] = useState({})
    const [billItem, setBillItem] = useState([])
    const [amountRecived, setAmountRecived] = useState('')
    const [inputError, setInputError] = useState('')
    const [invoiceItem, setInvoiceItem] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [layoutLoading, setlayoutLoading] = useState(false);
    const [payment, setPayment] = useState('1')
    const [chequeNo, setChequeNo] = useState('')
    const [deleteItem, setDeleteItem] = useState({})
    const [openRight, setOpenRight] = useState(false);
    const openDrawerRight = () => setOpenRight(true);
    const [popup, setPopup] = useState(false)
    const [submitMessage, setSubmitMessage] = useState(false)
    const [showBtn, setShowBtn] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    const GetInitialData = async () => {
        setlayoutLoading(true);
        await CompanyAllData();
        await InvoicePaginate();
        await TruckAllData();
        setlayoutLoading(false);
    }
    useEffect(() => {
        GetInitialData();
    }, [])
    useEffect(() => {
        InvoicePaginate()
    }, [currentInvoicePage, monthFilterInvoice, invoiceCompanyFilter, invoiceTruckFilter, invoiceStatusFilter])
    const handleDelete = async (item) => {
        setDeleteLoading(true)
        item.ordersArray?.forEach(async (e) => {
            try {
                await fetch(`${OrderAPI}/${e._id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authenticToken}`
                    },
                    body: JSON.stringify({ invoiceCreated: 0 })
                });
            } catch (error) {
                console.error('Error in updating orders in Invoice Table:', error);
            }
        })
        const companyDepthUpdate = companyData?.find((i) => i._id === item.companyInvoiceName._id)
        try {
            await fetch(`${CompanyAPI}/${companyDepthUpdate._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
                body: JSON.stringify({ depth: companyDepthUpdate.depth - item.total })
            });
        } catch (error) {
            console.error('Error in updating orders in Invoice Table:', error);
        }
        await fetch(`${InvoiceAPI}/${item._id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authenticToken}`
            },
        })
        setSubmitMessage(`Invoice Deleted`)
        setShowBtn(false)
        setPopup(true)
        InvoicePaginate()
        setDeleteLoading(false)
    }
    const formSubmit = async (e) => {
        e.preventDefault()
        if (parseInt(amountRecived) >= 1) {
            if (parseInt(amountRecived) <= parseInt(recievingItem.remaining_amount)) {
                setIsLoading(true)
                try {
                    await fetch(`${CompanyAPI}/${recievingItem.companyInvoiceName._id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authenticToken}`
                        },
                        body: JSON.stringify({ depth: recievingItem.companyInvoiceName.depth - parseInt(amountRecived) })
                    });
                } catch (error) {
                    console.error('Error in invoice updating company depth', error);
                }
                const id = Date.now()
                const bill = {
                    billNo: "B-" + id,
                    invoiceNo: recievingItem.invoiceNo,
                    invoiceId: recievingItem._id,
                    amountRecived: parseInt(amountRecived),
                    date: new Date(id).toLocaleString(),
                    paymentMethod: payment == 2 ? chequeNo : ''
                }
                try {
                    await fetch(BillAPI, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authenticToken}`
                        },
                        body: JSON.stringify(bill),
                    })
                } catch (error) {
                    console.log('An error occurred in bill Post!', error);
                }
                const amount = parseInt(recievingItem.remaining_amount) - parseInt(amountRecived)
                const invoiceUpdate = {
                    remaining_amount: amount,
                    invoicePaid: amount === 0 ? 1 : 0
                }
                try {
                    await fetch(`${InvoiceAPI}/${recievingItem._id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authenticToken}`
                        },
                        body: JSON.stringify(invoiceUpdate)
                    });
                } catch (error) {
                    console.error('Error in invoice updating invoice status', error);
                }
                setAmountRecived('')
                setChequeNo('')
                setPayment('1')
                setShowForm(false)
                setInputError('')
                InvoicePaginate()
                setIsLoading(false)
            } else {
                setInputError('Amount Should be lesser than Remaining Amount')
            }
        } else {
            setInputError('Recieving Should be greater than 0 ')
        }
    }
    const handlePdf = () => {
        const input = document.getElementById('page');
        input.style.display = 'block'; // Show the hidden page
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4');
                var width = pdf.internal.pageSize.getWidth();
                // var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, width, 0);
                pdf.save("Invoice.pdf");
                input.style.display = 'none';
            });
        input.style.display = 'none';
    };
    if (layoutLoading) {
        return (<h1 className='m-auto h-screen text-2xl'>loading...</h1>)
    } else {
        return (
            <div className=''>
                <div className='flex justify-between '>
                    <div className=' text-xl md:text-3xl text-cyan-800 font-normal pl-3'>Invoice Table</div>
                    <button onClick={() => navigate('/invoiceform')} className='text_size py-1 px-1 md:py-2.5 md:px-2.5 me-2 mb-2 text-sm md:text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>Create Invoice</button>
                </div>
                <div className=" mt-5 ml-1 ">
                    <div className='dropdown'>
                        <FormControl fullWidth size="small">
                            <InputLabel className='labelselect' id="demo-select-small-label" >Company Filters</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={invoiceCompanyFilter}
                                label="Company Filters"
                                onChange={(e) => setInvoiceCompanyFilter(e.target.value)}
                                className='select'
                            >
                                <MenuItem className='selectfonts' value=''>
                                    None
                                </MenuItem>
                                {companyData?.map(({ name, _id }, index) => (
                                    <MenuItem className='selectfonts' key={index} value={_id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <InputLabel className='labelselect' id="demo-select-small-label">Truck Filters</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={invoiceTruckFilter}
                                label="Truck Filters"
                                onChange={(e) => setInvoiceTruckFilter(e.target.value)}
                                className='select'
                            >
                                <MenuItem className='selectfonts' value=''>
                                    None
                                </MenuItem>
                                {truckData?.map(({ truckNumber, _id }, index) => (
                                    <MenuItem className='selectfonts' key={index} value={_id}>
                                        {truckNumber}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <InputLabel className='labelselect' id="demo-select-small-label">Month Filters</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={monthFilterInvoice}
                                label="Month Filters"
                                onChange={(e) => setMonthFilterInvoice(e.target.value)}
                                className='select'

                            >
                                <MenuItem className='selectfonts' value=''>
                                    None
                                </MenuItem>
                                {month?.map(({ label, value }, index) => (
                                    <MenuItem className='selectfonts' key={index} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <InputLabel className='labelselect' id="demo-select-small-label">Status Filters</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={invoiceStatusFilter}
                                label="Status Filters"
                                onChange={(e) => setInvoiceStatusFilter(e.target.value)}
                                className='select'
                            >
                                <MenuItem className='selectfonts' value=''>
                                    None
                                </MenuItem>
                                <MenuItem className='selectfonts' value={1}>
                                    Paid invoices
                                </MenuItem>
                                <MenuItem className='selectfonts' value={2}>
                                    UnPaid invoices
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <div className='w-40  md:m-0'>
                            <button onClick={() => { setInvoiceStatusFilter(''); setMonthFilterInvoice(''); setInvoiceTruckFilter(''); setInvoiceCompanyFilter('') }} className="select selectfonts float-start bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold py-2 px-5 rounded inline-flex items-center">
                                <span className='m-auto'>Clear</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col ml-4">
                    <div className="overflow-x-auto w-full sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light">
                                    <thead className="head border-b font-medium dark:border-neutral-500">
                                        <tr>
                                            {TABLE_HEAD.map((head) => (
                                                <th key={head} scope="col" className="text_size px-3 py-4 text_size">{head}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoicePaginateData?.map((item) => {
                                            return (
                                                <tr key={item._id} className="border-b dark:border-neutral-500">
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium on-full-screen">
                                                        <FormControl size="small" sx={{ height: '10px' }}>
                                                            <Select
                                                                sx={{
                                                                    maxHeight: '15px',
                                                                    color: "white",
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'white',
                                                                    },
                                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'white',
                                                                    },
                                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                        borderColor: 'white',
                                                                    }
                                                                }}
                                                                id="select-filter-by-field"
                                                            >
                                                                <MenuItem className='selectfonts' onClick={() => {
                                                                    if (item.invoicePaid === 0) {
                                                                        setRecievingItem(item); setShowForm(true);
                                                                    }
                                                                }}>
                                                                    {item.invoicePaid === 0 ?
                                                                        'Amount Recieve'
                                                                        : 'Invoice Paid'
                                                                    }
                                                                </MenuItem>
                                                                <MenuItem className='selectfonts' onClick={() => { setShowBillTable(true); setBillItem(item) }}>
                                                                    View Bills
                                                                </MenuItem>
                                                                <MenuItem className='selectfonts' onClick={() => { openDrawerRight(); setInvoiceItem(item); }}>
                                                                    Download
                                                                </MenuItem>
                                                                <MenuItem className='selectfonts' onClick={() => {
                                                                    if (item.bills.length !== 0) {
                                                                        setPopup(true)
                                                                        setSubmitMessage("You Cant Delete this invoice because there is bill Against it");
                                                                        setShowBtn(false)
                                                                    } else {
                                                                        setShowBtn(true)
                                                                        setDeleteItem(item);
                                                                        setPopup(true);
                                                                        setSubmitMessage('Are you sure you want to delete this invoice?')
                                                                    }
                                                                }
                                                                }>
                                                                    Delete
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl></td>
                                                    <td onClick={() => console.log(item)} className="text_size whitespace-nowrap px-3 py-4 font-medium">{item.invoiceNo || 'not recorded'}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium"> {item.dateOfInvoice}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium">{item.companyInvoiceName.name || 'company'}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium" title={item.truckInvoiceName.map(truck => truck.truckNumber).join(', ')}>{item.truckInvoiceName?.length < 2 ? item.truckInvoiceName[0].truckNumber : 'Multiple trucks'}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium">{item.dateFrom} / {item.dateTo}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium"> Rs. {item.remaining_amount || 0}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium">Rs. {item.total}</td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium response">
                                                        {item.invoicePaid === 0 ?
                                                            <button title='Amount Recieve' onClick={() => { setRecievingItem(item); setShowForm(true); }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                                                                </svg>
                                                            </button> :
                                                            <button title='Invoice Paid'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                                                            </svg>
                                                            </button>
                                                        }
                                                    </td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium response">
                                                        <button title='View Bill(s)' className='m-auto' onClick={() => { setShowBillTable(true); setBillItem(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium response">
                                                        <button title='Download As PDF' className='ml-4' onClick={() => { openDrawerRight(); setInvoiceItem(item); }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                    <td className="text_size whitespace-nowrap px-3 py-4 font-medium response">
                                                        <button title='Delete Invoice' onClick={() => {
                                                            if (item.bills.length !== 0) {
                                                                setPopup(true)
                                                                setSubmitMessage("You Cant Delete this invoice because there is bill Against it");
                                                                setShowBtn(false)
                                                            } else {
                                                                setShowBtn(true)
                                                                setDeleteItem(item);
                                                                setPopup(true);
                                                                setSubmitMessage('Are you sure you want to delete this invoice?')
                                                            }
                                                        }}
                                                            className='ml-4'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {invoiceTotalPages > 1 ?
                    < Pagination
                        currentPage={currentInvoicePage}
                        setCurrentPage={setCurrentInvoicePage}
                        totalPages={invoiceTotalPages} />
                    : null}
                {
                    popup &&
                    <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="border-cyan-100 border-2 p-4 md:p-5 text-center">
                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{submitMessage}</h3>
                                    {showBtn ?
                                        <div>
                                            {deleteLoading ?
                                                <button disabled type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                    </svg>
                                                    Loading...
                                                </button> :
                                                <button onClick={() => { handleDelete(deleteItem); setPopup(false) }} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                    Delete
                                                </button>}
                                            <button onClick={() => setPopup(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                                        </div>
                                        :
                                        <button onClick={() => { setPopup(false) }} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                            OK
                                        </button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    showForm ?
                        <div className='overlay'>
                            <div className="modal w-72">
                                <form className="space-y-6 " onSubmit={formSubmit}>
                                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">Enter Amount</h5>
                                    <div className='px-0'>
                                        <div className='float-left text-sm'>Company Depth Amount:</div><span className='float-right text-sm mr-2'>Rs. {recievingItem.companyInvoiceName.depth || 0}</span><br />
                                        <div className='float-left text-sm'>Current Invoice Amount:</div><span className='float-right text-sm mr-2'>Rs. {recievingItem.total || 0}</span>
                                        <div className='float-left text-sm'>Payable Amount:</div><span className='float-right text-sm mr-2'>Rs. {recievingItem.remaining_amount || 0}</span>
                                    </div>
                                    <FormControl sx={{ m: 0, minWidth: 250, mb: 2 }} size="small">
                                        <InputLabel id="demo-select-small-label">Payment Method</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            value={payment}
                                            onChange={(e) => setPayment(e.target.value)}
                                            label="Payment Method"
                                        >
                                            <MenuItem className='selectfonts' value='1' selected>
                                                Cash
                                            </MenuItem>
                                            <MenuItem className='selectfonts' value='2'>
                                                Cheque
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    <div>
                                        <label>Amount Recieve</label>
                                        <input
                                            type="number"
                                            id=""
                                            onChange={(e) => setAmountRecived(e.target.value)}
                                            value={amountRecived}
                                            className={`mb-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                            placeholder="900"
                                        />
                                        {inputError && <div className="text-red-500 text-sm">{inputError}</div>}
                                        {payment == 2 ?
                                            <>
                                                <label>Cheque Number</label>
                                                <input
                                                    type="text"
                                                    id=""
                                                    required
                                                    onChange={(e) => setChequeNo(e.target.value)}
                                                    value={chequeNo}
                                                    className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                                                    placeholder="***********"
                                                />
                                            </>
                                            :
                                            ''}
                                    </div>
                                    {
                                        isLoading ?
                                            <button disabled type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                </svg>
                                                Loading...
                                            </button>
                                            :
                                            <div>
                                                <button type='submit'
                                                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                >
                                                    Save
                                                </button>
                                                <button type='button'
                                                    onClick={() => { setShowForm(false); setAmountRecived(''); setInputError(''); setPayment('1') }}
                                                    className="ml-1 bg-transparent text-gray-700 font-semibold py-2 px-2  hover:border-transparent rounded"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                    }
                                </form>
                            </div>
                        </div >
                        :
                        ''
                }
                {showBillTable ?
                    <BillTable
                        showBillTable={showBillTable}
                        setShowBillTable={setShowBillTable}
                        billItem={billItem}
                        Bill_TABLE={Bill_TABLE}
                        BillAPI={BillAPI}
                    />
                    : null}
                <div id="page" className="hidden pl-2 mx-auto py-16  bg-white">
                    {
                        Object.keys(invoiceItem).length > 0 ? (

                            <div className="invoiceContainer2 space-y-32 ">
                                <div className="flex justify-center">
                                    <div className="space-y-3 text-slate-700">
                                        <p className="text-5xl font-extrabold tracking-tight uppercase font-body">
                                            Invoice
                                        </p>
                                    </div>
                                </div>
                                <div className="ml-10  flex space-x-8 font-semibold text-lg mt-20 mb-20 " >
                                    <ul className="text-left font-bold" >
                                        <li>Corporation Name:</li>
                                        <li>Email Address:</li>
                                        <li>Contact:</li>
                                        <li>Invoice Duration:</li>
                                        <li>Invoice NO:</li>
                                    </ul>
                                    <ul className="text-left">
                                        <li>{invoiceItem.companyInvoiceName.name}</li>
                                        <li>{invoiceItem.companyInvoiceName.email || '-'}</li>
                                        <li>{invoiceItem.companyInvoiceName.contact || '-'}</li>
                                        <li>{invoiceItem.dateFrom} - {invoiceItem.dateTo}</li>
                                        <li>{invoiceItem.invoiceNo}</li>
                                    </ul>
                                </div>
                                <div className="rounded-lg relative  shadow-md sm:rounded-lg">
                                    <table className="rounded-lg w-full text-md text-left rtl:text-right text-gray-700 dark:text-gray-400">
                                        <thead style={{ background: ' #428bca' }} className="rounded-lg text-xs  text-white dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Date
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Truck No./Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    From (City)
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    To (City)
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Note
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoiceItem.ordersArray?.map((item) =>
                                                <tr key={item._id} className="border-y-2">
                                                    <td scope="col" className="bg-white px-6 py-3">
                                                        {item.date}
                                                    </td>
                                                    <td scope="col" className=" bg-white px-6 py-3">
                                                        {invoiceItem.truckInvoiceName.find((f) => f._id === item.truck).truckNumber}
                                                    </td>
                                                    <td scope="col" className="bg-white px-6 py-3">
                                                        {item.from}
                                                    </td>
                                                    <td scope="col" className="bg-white px-6 py-3">
                                                        {item.to}
                                                    </td>
                                                    <td scope="col" className="bg-white px-6 py-3">
                                                        {item.describtion || "No Notes"}
                                                    </td>
                                                    <td scope="col" className="bg-white px-6 py-3">
                                                        Rs. <span className='font-medium'>{item.price}</span>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr style={{ background: ' #428bca' }} className=" dark:bg-gray-800 text-white dark:hover:bg-gray-600">
                                                <th className="px-6 py-4 ">
                                                </th>
                                                <td className="px-6 py-4">
                                                </td>
                                                <td className="px-6 py-4">

                                                </td>
                                                <td className="px-6 py-4">

                                                </td>
                                                <td className="px-6 py-4 font-medium">
                                                    SubTotal
                                                </td>
                                                <th className="px-6 py-4 font-medium text-white text_size whitespace-nowrap dark:text-white">
                                                    Rs. {invoiceItem.total}
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                {openRight ?
                    <DrawerPlacement
                        openRight={openRight}
                        setOpenRight={setOpenRight}
                        invoiceItem={invoiceItem}
                        openDrawerRight={openDrawerRight}
                        handlePdf={handlePdf}
                    /> : null}

            </div>
        );
    }
}

export default InvoiceTable