import React, { useState, createContext, useContext } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";

export const TruckContext = createContext();
const TruckProvider = ({ children }) => {
    const { authenticToken } = useContext(AuthContext)
    const [currentTruckPage, setCurrentTruckPage] = useState(1)
    const [truckTotalPages, setTruckTotalPages] = useState(0);
    const [truckData, setTruckData] = useState([]);
    const TruckAPI = `${api}/trucks`
    const TruckAllData = async () => {
        try {
            const response = await fetch(TruckAPI, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setTruckData(data);
        } catch (err) {
            console.log('An Error Occurred at Company provider:', err);
        }
    }
    const TruckAdded = async () => {
        try {
            const response = await fetch(TruckAPI + `/paginate?page=${currentTruckPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setTruckTotalPages(data.totalPages)
            setTruckData(data.data);
        } catch (err) {
            console.log('An Error Occurred at Truck provider:', err);
        }
    };

    const TruckState = {
        truckData,
        setTruckData,
        setTruckTotalPages,
        truckTotalPages,
        currentTruckPage,
        setCurrentTruckPage,
        TruckAdded,
        TruckAllData,
        TruckAPI
    }


    return (
        <TruckContext.Provider value={TruckState}>
            {children}
        </TruckContext.Provider>
    )
}
export { TruckProvider };