import React, { useState, createContext, useContext, useEffect } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";

export const InvoiceOrderContext = createContext();
const InvoiceOrderProvider = ({ children }) => {
    const [invoiceAllData, setInvoiceAllData] = useState([]);
    const [invoicePaginateData, setInvoicePaginateData] = useState([]);
    const { authenticToken } = useContext(AuthContext)
    const [currentInvoicePage, setCurrentInvoicePage] = useState(1)
    const [invoiceTotalPages, setInvoiceTotalPages] = useState(0);
    const [monthFilterInvoice, setMonthFilterInvoice] = useState('')
    const [invoiceCompanyFilter, setInvoiceCompanyFilter] = useState('')
    const [invoiceTruckFilter, setInvoiceTruckFilter] = useState('')
    const [invoiceStatusFilter, setInvoiceStatusFilter] = useState('')
    const TABLE_HEAD = ["Actions", "Invoice No", "Invoice Date", "Company", 'Truck', 'Duration Date', 'Payable', 'Total', 'Recieve', 'Bills', 'Download', 'Delete'];
    const InvoiceAPI = `${api}/invoices`
    useEffect(() => {
        setCurrentInvoicePage(1)
    }, [monthFilterInvoice, invoiceCompanyFilter, invoiceTruckFilter, invoiceStatusFilter])
    const InvoiceAllData = async () => {
        try {
            const response = await fetch(InvoiceAPI, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setInvoiceAllData(data)
        } catch (err) {
            console.log('An Error Occurred at Invoice provider:', err);
        }
    }

    const InvoicePaginate = async () => {
        try {
            const response = await fetch(`${InvoiceAPI}/paginate?page=${currentInvoicePage}&company=${invoiceCompanyFilter}&truck=${invoiceTruckFilter}&month=${monthFilterInvoice}&status=${invoiceStatusFilter}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setInvoiceTotalPages(data.totalPages)
            setInvoicePaginateData(data.data);
        } catch (err) {
            console.log('An Error Occurred at Invoice paginate provider:', err);
        }
    }
    const InvoiceOrderState = {
        currentInvoicePage,
        setCurrentInvoicePage,
        invoiceTotalPages,
        InvoiceAllData,
        invoicePaginateData,
        invoiceCompanyFilter,
        monthFilterInvoice,
        invoiceTruckFilter,
        invoiceStatusFilter,
        setMonthFilterInvoice,
        setInvoiceCompanyFilter,
        setInvoiceTruckFilter,
        setInvoiceStatusFilter,
        InvoicePaginate,
        invoiceAllData,
        TABLE_HEAD,
        InvoiceAPI
    }
    return (
        <InvoiceOrderContext.Provider value={InvoiceOrderState}>
            {children}
        </InvoiceOrderContext.Provider>
    )
}
export { InvoiceOrderProvider };