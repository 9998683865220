
import React, { useContext, useEffect, useState } from 'react'
import { CompanyContext } from '../Contexts/CompanyProvider'
import { TruckContext } from '../Contexts/TruckProvider'
import { OrderContext } from '../Contexts/OrderProvider'
import { PlacesContext } from '../Contexts/PlacesProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import Autocomplete from './AutoComplete'
import { AuthContext } from '../Contexts/AuthProvider'

const OrderForm = () => {
    const { state } = useLocation();
    const data = state || '';
    const { companyData, CompanyAllData } = useContext(CompanyContext)
    const { authenticToken } = useContext(AuthContext)
    const { OrderAll, OrderAPI } = useContext(OrderContext)
    const { truckData, TruckAllData } = useContext(TruckContext)
    const { City, CityAdded, PlaceAPI } = useContext(PlacesContext)
    const [truck, setTruck] = useState(data ? data.truck.truckNumber : '')
    const [company, setCompany] = useState(data ? data.company.name : '')
    const [price, setPrice] = useState(data ? data.price : '')
    const [fuelPrice, setFuelPrice] = useState(data.fuel_price || '')
    const [labour, setLabour] = useState(data.labour || '')
    const [placeFrom, setPlaceFrom] = useState(data ? data.from : '')
    const [placeTo, setPlaceTo] = useState(data ? data.to : '')
    const [describtion, setDescribtion] = useState(data ? data.describtion : '')
    const [companyError, setCompanyError] = useState('')
    const [truckError, setTruckError] = useState('')
    const [formLoading, setFormLoading] = useState(false)
    const navigate = useNavigate()
    const [popup, setPopup] = useState(false)
    const [popUpNotify, setPopUpNotify] = useState('')
    useEffect(() => {
        CompanyAllData()
        CityAdded()
        TruckAllData()
        OrderAll()
    }, [])
    const handleOrderSubmit = async (e) => {
        e.preventDefault();
        setCompanyError("")
        setTruckError("")
        const companyitem = companyData?.find((item) => item.name === company)
        const truckinfo = truckData?.find((t) => t.truckNumber === truck)
        if (companyitem) {
            if (truckinfo) {
                setFormLoading(true)

                let placeToCheck = '';
                City?.forEach((i) => {
                    const lowerCase = placeTo.toLowerCase()
                    const placeInput = i.place.toLowerCase()
                    if (lowerCase === placeInput) {
                        return placeToCheck = i.place
                    }
                })
                if (placeToCheck === '') {
                    const placeTocap = placeTo?.charAt(0).toUpperCase() + placeTo.slice(1)
                    const data = {
                        place: placeTocap
                    }
                    try {
                        await fetch(PlaceAPI, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authenticToken}`
                            },
                            body: JSON.stringify(data),
                        })
                    } catch (error) {
                        console.log('An error occurred in Order Post!', error);
                    }
                }
                //check if place doesnt match
                let placeFromCheck = '';
                City?.forEach((i) => {
                    const lowerCase = placeFrom.toLowerCase()
                    const placeInput = i.place.toLowerCase()
                    if (lowerCase === placeInput) {
                        return placeFromCheck = i.place
                    }
                })
                if (placeTo !== placeFrom) {
                    if (placeFromCheck === "") {
                        const placeTocap = placeFrom?.charAt(0).toUpperCase() + placeFrom.slice(1)
                        const data = {
                            place: placeTocap
                        }
                        try {
                            await fetch(PlaceAPI, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${authenticToken}`
                                },
                                body: JSON.stringify(data),
                            })
                        } catch (error) {
                            console.log('An error occurred in Order Post!', error);
                        }
                    }
                }
                const placeTocap = placeTo.charAt(0).toUpperCase() + placeTo.slice(1)
                const placeFromCap = placeFrom.charAt(0).toUpperCase() + placeFrom.slice(1)
                const orderId = Date.now()
                if (data) {
                    const dataItem = {
                        truck: truckinfo._id,
                        company: companyitem._id,
                        price: parseFloat(price),
                        fuel_price: fuelPrice ? parseFloat(fuelPrice) : 0,
                        labour: labour ? parseFloat(labour) : 0,
                        to: placeToCheck || placeTocap,
                        from: placeFromCheck || placeFromCap,
                        describtion: describtion || '',
                    }
                    try {
                        const response = await fetch(`${OrderAPI}/${data._id}`, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authenticToken}`
                            },
                            body: JSON.stringify(dataItem)
                        })
                        setPopUpNotify("Order Edited")
                        setCompany('')
                        setTruck('')
                        setPrice('')
                        setFuelPrice('')
                        setDescribtion('')
                        setLabour('')
                        setPlaceFrom('')
                        setPlaceTo('')
                        setCompanyError('')
                        setFormLoading(false)
                        setPopup(true)
                        OrderAll()
                    } catch (error) {
                        console.error('Error in invoice updating company form', error);
                    }
                    setPopUpNotify("Order Edited")
                } else {
                    const data = {
                        orderSNo: "O-" + orderId,
                        truck: truckinfo._id,
                        company: companyitem._id,
                        price: parseFloat(price),
                        fuel_price: fuelPrice ? parseFloat(fuelPrice) : 0,
                        labour: labour ? parseFloat(labour) : 0,
                        to: placeToCheck || placeTocap,
                        from: placeFromCheck || placeFromCap,
                        describtion: describtion || '',
                        date: new Date(orderId).toLocaleString()
                    }
                    try {
                        const response = await fetch(OrderAPI, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${authenticToken}`
                            },
                            body: JSON.stringify(data),
                        })
                        setPopUpNotify("Order Placed")
                        setCompany('')
                        setTruck('')
                        setPrice('')
                        setFuelPrice('')
                        setDescribtion('')
                        setLabour('')
                        setPlaceFrom('')
                        setPlaceTo('')
                        setCompanyError('')
                        setFormLoading(false)
                        setPopup(true)
                        OrderAll()
                    } catch (error) {
                        console.log('An error occurred in Order Post!', error);
                    }
                }
            } else {
                setTruckError("Truck Not Registered")
            }
        } else {
            setCompanyError("Company Not Registered")
        }
    }
    return (
        <div>
            <div className="m-auto w-full  p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-6" onSubmit={handleOrderSubmit}>
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">Order Form</h5>
                    <div className="sm:grid w-64 m-auto md:w-96">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Truck
                        </label>
                        <Autocomplete
                            optionsSelected={(p) => setTruck(p)}
                            options={truckData.map((c) => c.truckNumber)}
                            isRequired={true}
                            valueEdit={data ? data.truck.truckNumber : ''}
                            placeHolder={"Enter Truck Name"}
                        />
                        {truckError && <div className="text-red-500 text-sm">{truckError}</div>}
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Company
                        </label>
                        <Autocomplete
                            optionsSelected={(p) => setCompany(p)}
                            valueEdit={data ? data.company.name : ''}
                            options={companyData.map((c) => c.name)}
                            placeHolder={"Enter Company Name"}
                            isRequired={true}
                        />
                        {companyError && <div className="text-red-500 text-sm">{companyError}</div>}
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            From (city)
                        </label>
                        <Autocomplete
                            optionsSelected={(p) => setPlaceFrom(p)}
                            valueEdit={data ? data.from : ''}
                            options={City.map((c) => c.place)}
                            isRequired={true}
                            placeHolder={"From Where"}
                        />
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            To (city)
                        </label>
                        <Autocomplete
                            optionsSelected={(p) => setPlaceTo(p)}
                            valueEdit={data ? data.to : ''}
                            options={City.map((c) => c.place)}
                            isRequired={true}
                            placeHolder={"Where to go"}
                        />
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Total price  (main)
                        </label>
                        <input
                            type="number"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className={`bg-gray-50 border border-gray-400 text-black-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                            placeholder="Enter Total Price"
                            required
                        />
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Fuel Price
                        </label>
                        <input
                            type="number"
                            value={fuelPrice}
                            onChange={(e) => setFuelPrice(e.target.value)}
                            className={`bg-gray-50 border border-gray-400 text-black-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                            placeholder="Enter Fuel Price"

                        />
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Labour Price
                        </label>
                        <input
                            type="number"
                            id=""
                            value={labour}
                            onChange={(e) => setLabour(e.target.value)}
                            className={`bg-gray-50 border border-gray-400 text-black-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white`}
                            placeholder="Enter Labour Price"
                        />
                    </div>
                    <div className="sm:grid m-auto w-64 md:w-96 mb-0">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your message</label>
                        <textarea
                            value={describtion}
                            onChange={(e) => setDescribtion(e.target.value)}
                            id="message"
                            rows="4"
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Leave a comment..."
                        ></textarea>
                    </div>
                    {formLoading ?
                        <button disabled type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                            </svg>
                            Loading...
                        </button>
                        :
                        <button
                            type="submit"
                            className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            Submit
                        </button>
                    }
                </form>


            </div >
            {popup &&
                <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 m-auto text-gray-300 mt-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <div className="p-4 md:p-5 text-center">
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{popUpNotify}</h3>
                                <button onClick={() => { setPopup(false); navigate(-1) }} type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default OrderForm