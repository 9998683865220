import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class Autocomplete extends Component {
    static propTypes = {
        options: PropTypes.instanceOf(Array).isRequired,
        optionsSelected: PropTypes.instanceOf(Function).isRequired,
        isRequired: PropTypes.bool.isRequired,
        valueEdit: PropTypes.string.isRequired,
        placeHolder: PropTypes.string.isRequired
    };
    constructor(props) {
        super(props);
        this.autocompleteRef = React.createRef();
    }
    state = {
        activeOption: 0,
        filteredOptions: [],
        showOptions: true,
        userInput: this.props.valueEdit
    };
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        if (this.autocompleteRef && !this.autocompleteRef.current.contains(event.target)) {
            this.setState({
                showOptions: false
            });
        }
    };
    onChange = (e) => {
        const { options } = this.props;
        const userInput = e.currentTarget.value;
        const filteredOptions = options.filter(
            (optionName) =>
                optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );
        this.setState({
            activeOption: 0,
            filteredOptions,
            showOptions: true,
            userInput: e.currentTarget.value
        });
    };

    onClick = (e) => {
        const { optionsSelected } = this.props;
        this.setState({
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: e.currentTarget.innerText
        });
        optionsSelected(e.currentTarget.innerText)
    };
    onBlur = (e) => {
        const { optionsSelected } = this.props;
        const { userInput } = this.state;
        optionsSelected(userInput);
    }
    onKeyDown = (e) => {
        const { activeOption, filteredOptions } = this.state;
        const { optionsSelected } = this.props;
        const { userInput } = this.state;
        if (e.keyCode === 13) {
            if (filteredOptions.length > 0) {
                const selectedOption = filteredOptions[activeOption];
                this.setState({
                    activeOption: 0,
                    showOptions: false,
                    userInput: selectedOption
                });
                optionsSelected(selectedOption);
            } else {
                this.setState({
                    activeOption: 0,
                    showOptions: false,
                });
                optionsSelected(userInput);
            }
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            this.setState({ activeOption: activeOption - 1 });
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                return;
            }
            this.setState({ activeOption: activeOption + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            onBlur,
            props: { isRequired, placeHolder },
            state: { activeOption, filteredOptions, showOptions, userInput }
        } = this;
        let optionList;
        if (showOptions) {
            if (filteredOptions.length) {
                optionList = (
                    <ul className="mt-1 options max-h-32 sm:min-w-96 min-w-64 overflow-x-hidden">
                        {filteredOptions.map((optionName, index) => {
                            let className;
                            if (index === activeOption) {
                                className = 'option-active';
                            }
                            return (
                                <li className={className} key={optionName} onClick={onClick}>
                                    {optionName}
                                    <hr />
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        }
        return (
            <React.Fragment>
                <div ref={this.autocompleteRef}>
                    <input
                        type="text"
                        placeholder={placeHolder}
                        className='bg-gray-50 border border-gray-400 text-black-800 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                        onChange={onChange}
                        onFocus={() => {
                            const { options } = this.props;
                            this.setState({
                                activeOption: 0,
                                filteredOptions: options,
                                showOptions: true,
                            });
                        }}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        value={userInput}
                        required={isRequired}
                    />
                    {optionList}
                </div>
            </React.Fragment>
        );
    }
}

export default Autocomplete;