import React, { useState, createContext, useContext } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";

export const UsersContext = createContext();
const UsersProvider = ({ children }) => {
    const [usersData, setUsersData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [usersRequestData] = useState([]);
    const { authenticToken } = useContext(AuthContext)
    const usersAPI = `${api}/users`;
    const UsersAdded = async () => {
        try {
            const response = await fetch(usersAPI + `?page=${currentPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setTotalPages(data.totalPages)
            setUsersData(data.data);
        } catch (err) {
            console.log('An Error Occurred at Users provider:', err);
        }
    };

    const usersState = {
        usersData,
        setUsersData,
        UsersAdded,
        usersAPI,
        usersRequestData,
        currentPage,
        totalPages,
        setCurrentPage
    }
    return (
        <UsersContext.Provider value={usersState}>
            {children}
        </UsersContext.Provider>
    )
}
export { UsersProvider };