import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TruckContext } from '../Contexts/TruckProvider'
import { FormControl, MenuItem, Select } from '@mui/material';
import { AuthContext } from '../Contexts/AuthProvider';
import { Pagination } from './reuseable_components/Pagination';


const TruckTable = () => {
    const navigate = useNavigate()
    const TruckTableHead = ["Actions", "Name/Number", "Engine", 'Length', 'Delete', "Edit"]
    const { truckData, TruckAdded, TruckAPI, truckTotalPages, currentTruckPage, setCurrentTruckPage, } = useContext(TruckContext)
    const { authenticToken } = useContext(AuthContext)
    const [item, setItem] = useState({})
    const [loadingPage, setLoadingPage] = useState(false)
    const [popups, setPopup] = useState(false)
    const [truckError, setTruckError] = useState('')
    const [showBtn, setShowBtn] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const getData = async () => {
        setLoadingPage(true)
        await TruckAdded()
        setLoadingPage(false)
    }
    useEffect(() => { getData(); }, [currentTruckPage])
    const handleDelete = async (item) => {
        setDeleteLoading(true);
        try {
            const response = await fetch(`${TruckAPI}/${item._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`,
                }
            });
        } catch (error) {
            console.error('An error occurred while trying to delete the truck:', error);
        }
        await TruckAdded();
        setDeleteLoading(false);
    }
    const handleCheckForDelete = async (item) => {
        setDeleteLoading(true);
        try {
            const response = await fetch(`${TruckAPI}/check/${item._id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`,
                }
            });

            if (response.status === 201) {
                setTruckError("You Can't delete this Truck because there is an Order against it")
                setPopup(true);
                setShowBtn(false)
            } else if (response.status === 200) {
                setTruckError('Are you sure you want to delete this Truck?')
                setPopup(true);
                setItem(item)
                setShowBtn(true)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDeleteLoading(false);
        }
    };

    if (loadingPage) {
        return (<h1 className='m-auto h-screen text-2xl'>loading...</h1>)
    } else {
        return (
            <div className=''>
                <div className='flex justify-between'>
                    <div className=' text-xl md:text-3xl text-cyan-800 font-normal pl-3'>Truck Table</div>
                    <button onClick={() => navigate('/truckform')} className='text_size py-1 px-1 md:py-2.5 md:px-2.5 me-2 mb-2 text-sm md:text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700'>Add Truck</button>
                </div>
                <div className="flex flex-col ml-4">
                    <div className="overflow-x-auto w-full sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light">
                                    <thead className="headTruck border-b font-medium dark:border-neutral-500">
                                        <tr>
                                            {TruckTableHead.map((head) => (
                                                <th key={head} scope="col" className="text_size px-6 py-4">{head}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {truckData?.map((item) => (
                                            <tr key={item._id} className=" border-b dark:border-neutral-500">
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium on-full-screen">
                                                    <FormControl size="small" sx={{ height: '10px' }}>
                                                        <Select
                                                            sx={{
                                                                height: '15px',
                                                                color: "white",
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'white',
                                                                },
                                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'white',
                                                                },
                                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: 'white',
                                                                }
                                                            }}
                                                            id="select-filter-by-field"
                                                        >
                                                            <MenuItem className='selectfonts' onClick={() => { navigate('/truckform', { state: item }) }}>
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem className='selectfonts' onClick={() => handleCheckForDelete(item)}>
                                                                Delete
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium"> {item.truckNumber}</td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium">{item.engineNumber || "Not Recorded"}</td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium">{item.truckLength || "Not Recorded"}</td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium response" >
                                                    <button title='Delete' onClick={() => handleCheckForDelete(item)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                        </svg>
                                                    </button>
                                                </td>
                                                <td className="text_size whitespace-nowrap px-6 py-4 font-medium response">
                                                    <button title='Edit' onClick={() => { navigate('/truckform', { state: item }) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="md:h-4 xm:h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                                                        </svg>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                {truckTotalPages > 1 ?
                    <Pagination
                        currentPage={currentTruckPage}
                        setCurrentPage={setCurrentTruckPage}
                        totalPages={truckTotalPages} />
                    : null}
                {
                    popups &&
                    <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="border-cyan-100 border-2 p-4 md:p-5 text-center">
                                    <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{truckError}</h3>
                                    {showBtn ?
                                        <div>
                                            {deleteLoading ?
                                                <button disabled type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none  hover:border-gray-700 bg-white rounded-lg border border-gray-400 hover:bg-gray-700 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                    </svg>
                                                    Loading...
                                                </button> :
                                                <button onClick={() => { handleDelete(item); setPopup(false) }} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                                    Delete
                                                </button>}
                                            <button onClick={() => setPopup(false)} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Cancel</button>
                                        </div>
                                        :
                                        <button onClick={() => { setPopup(false) }} data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                            OK
                                        </button>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div >
        )
    }
}

export default TruckTable