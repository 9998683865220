import React, { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();
const AuthProvider = ({ children }) => {
    const [logginStatus, setLogginStatus] = useState(false)
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [authenticToken, setAuthenticToken] = useState('')
    const checkForSessionValues = () => {
        const username = sessionStorage.getItem('username');
        const userid = sessionStorage.getItem('userid');
        const useremail = sessionStorage.getItem('useremail');
        const authToken = sessionStorage.getItem('authToken');
        if (username && userid && useremail && authToken) {
            setLogginStatus(true);
            setUserName(JSON.parse(username))
            setUserEmail(JSON.parse(useremail))
            setAuthenticToken(authToken)
        }
    }
    useEffect(() => {
        checkForSessionValues()
    }, [])
    const authState = {
        checkForSessionValues,
        userName,
        userEmail,
        authenticToken,
        logginStatus,
        setLogginStatus
    }
    return (
        <AuthContext.Provider value={authState}>
            {children}
        </AuthContext.Provider>
    )
}
export { AuthProvider };