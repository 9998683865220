import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h1 className='text-2xl'>You need to <Button onClick={() => navigate("/")}> Sign In</Button> to get access Of this page</h1>
    </div>
  )
}

export default PageNotFound