import React, { useState } from "react";
import {
    Drawer,
    Typography,
    IconButton,
} from "@material-tailwind/react";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export function DrawerPlacement({ openRight,
    setOpenRight,
    invoiceItem }) {
    const closeDrawerRight = () => setOpenRight(false);
    const [showPopup, setPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const handlePdf = () => {
        setPopup(true)
        setIsLoading(true)
        const input = document.getElementById('page');
        input.style.display = 'block'; // Show the hidden page
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'pt', 'a4');
                var width = pdf.internal.pageSize.getWidth();
                // var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(imgData, 'JPEG', 0, 0, width, 0);
                pdf.save("Invoice.pdf");
                input.style.display = 'none';
            });
        input.style.display = 'none';
        setIsLoading(false)
    };

    return (
        <React.Fragment>
            <Drawer
                placement="right"
                open={openRight}
                onClose={closeDrawerRight}
                className=""
                overlay={false}
                size={1500}
            >
                <div className="mb-3 ml-4 flex items-center justify-between">
                    <Typography variant="h5" color="blue-gray">
                        Do you want to print this Invoice?
                    </Typography>
                    <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={closeDrawerRight}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>
                <div className=" max-w-5xl mx-auto lg:py-0 md:py-4 py-0 bg-white">
                    <div className="invoiceContainer ">
                        <div className="flex justify-center">
                            <div className="space-y-3 text-slate-700">
                                <p className="md:text-5xl sm:text-sm font-extrabold tracking-tight uppercase font-body">
                                    Invoice
                                </p>
                            </div>
                        </div>
                        <div className="md:ml-10 sm:ml-3 flex space-x-8 font-semibold  lg:text-lg font_size  md:mt-20 md:mb-20 mt-2 mb-2 " >
                            <ul className="text-left font-bold" >
                                <li>Corporation Name:</li>
                                <li>Email Address:</li>
                                <li>Contact:</li>
                                <li>Invoice Duration:</li>
                                <li>Invoice NO:</li>
                            </ul>
                            <ul className="text-left">
                                <li>{invoiceItem.companyInvoiceName.name}</li>
                                <li>{invoiceItem.companyInvoiceName.email || '-'}</li>
                                <li>{invoiceItem.companyInvoiceName.contact || '-'}</li>
                                <li>{invoiceItem.dateFrom} - {invoiceItem.dateTo}</li>
                                <li>{invoiceItem.invoiceNo}</li>
                            </ul>
                        </div>
                        <table className="rwd-table w-full font_size">
                            <tbody>
                                <tr>
                                    <th>Date</th>
                                    <th>Truck No./Name</th>
                                    <th>From(City)</th>
                                    <th>To(City)</th>
                                    <th>Note</th>
                                    <th>Amount</th>
                                </tr>
                                {
                                    invoiceItem.ordersArray.map((item) =>
                                        <tr key={item._id}>
                                            <td data-th="Order No">
                                                {item.date}
                                            </td>
                                            <td data-th="Truck No./Name">
                                                {invoiceItem.truckInvoiceName.find((f) => f._id === item.truck).truckNumber}
                                            </td>
                                            <td data-th="From (City)">
                                                {item.from}
                                            </td>
                                            <td data-th="To (City)">
                                                {item.to}
                                            </td>
                                            <td data-th="To (City)">
                                                {item.describtion || 'No Notes'}
                                            </td>
                                            <td data-th="Amount">
                                                Rs. {item.price}
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        Total
                                    </th>
                                    <td data-th="SubTotal">
                                        Rs. {invoiceItem.total}
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div className="flex gap-2 justify-center">
                    <div className='w-80  md:m-0 flex gap-3'>
                        <button style={{ width: "152px" }} onClick={() => handlePdf()} className="border-gray-400 border-2 float-start bg-green-300 hover:border-green-800 hover:bg-green-800 hover:text-white text-gray-800 font-bold py-2 px-5 rounded inline-flex items-center">
                            <span className='m-auto'>Print Invoice</span>
                        </button>
                        <button style={{ width: "152px" }} onClick={() => setOpenRight(false)} className="border-gray-400 border-2 hover:border-red-800 float-start bg-red-300 hover:bg-red-800 hover:text-white text-gray-800 font-bold py-2 px-5 rounded inline-flex items-center">
                            <span className='m-auto'>Cancel</span>
                        </button>
                    </div>
                </div>
                {showPopup ?
                    <div tabIndex="-1" className="fade-up h-screen flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full">
                        <div className="relative p-4 w-full max-w-md max-h-full">
                            {isLoading ?
                                <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <svg className="mx-auto mt-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <div className="p-4 md:p-5 text-center">
                                        <h3 className="mb-5 text-lg font-normal text-gray-600 dark:text-gray-400">Please Wait</h3>
                                        <button type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                            <div role="status">
                                                <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                </svg>
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="border-cyan-100 border-2 relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24 m-auto text-gray-300 mt-3">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                    <div className="p-4 md:p-5 text-center">
                                        <h3 className="mb-5 text-lg font-normal text-gray-600 dark:text-gray-400">Invoice printed successfully</h3>
                                        <button type="button" onClick={() => { setOpenRight(false); setPopup(false) }} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                            Done
                                        </button>
                                    </div>
                                </div>

                            }
                        </div>
                    </div>
                    : ''}
            </Drawer>
        </React.Fragment >
    );
}