import React, { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Popup from './Popups';
import { AuthContext } from '../Contexts/AuthProvider';
import { api } from '../API\'s';
function ResponsiveAppBar() {
    const { setLogginStatus, logginStatus, checkForSessionValues, userName, userEmail, authenticToken } = useContext(AuthContext)
    useEffect(() => {
        checkForSessionValues()
    }, [logginStatus])
    const createDBBackup = async () => {
        const logOutApi = `${api}/logout`
        try {
            await fetch(logOutApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}`
                },
            });
        } catch (err) {
            console.log('An Error Occurred at log Out:', err);
        }
    }
    const LogOut = async () => {
        await createDBBackup()
        setLogginStatus(false)
        sessionStorage.clear();
        navigate('/')
    }
    const navigate = useNavigate()
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const navPaths = [
        {
            label: "User Table",
            link: "/usertable"
        }, {
            label: "Truck Table",
            link: "/trucktable"
        }, {
            label: "Company Table",
            link: "/companytable"
        }, {
            label: "Order Table",
            link: "/ordertable"
        }, {
            label: "Invoice Table",
            link: "/invoicetable"
        }
    ]
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar className='mb-4' color='' position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                        <img src="https://png.pngtree.com/png-clipart/20220911/original/pngtree-delivery-truck-logo-png-image_8538794.png" alt="Logo" style={{ height: '40px' }} />
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => navigate('/')}
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            cursor: 'pointer',
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Truck App
                    </Typography>
                    {logginStatus ?
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e) => setAnchorElNav(e.currentTarget)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    minHeight: 'auto'
                                }}
                            >
                                {navPaths.map((page) => (
                                    <MenuItem
                                        sx={{
                                            minHeight: 'auto'
                                        }}
                                        key={page.label} onClick={() => { handleCloseNavMenu(); navigate(page.link) }}>
                                        <Typography fontFamily="Poppins" fontSize='10px' textAlign="center">{page.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> : null}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
                        <img src="https://png.pngtree.com/png-clipart/20220911/original/pngtree-delivery-truck-logo-png-image_8538794.png" alt="Logo" style={{ height: '40px' }} />
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        onClick={() => navigate('/')}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            cursor: 'pointer',
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            letterSpacing: '.1rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Truck App
                    </Typography>
                    {logginStatus ?
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {navPaths.map((page) => (
                                <Button
                                    key={page.label}
                                    onClick={() => { handleCloseNavMenu(); navigate(page.link) }}
                                    sx={{ my: 2, color: 'black', display: 'block', textTransform: 'capitalize', fontWeight: '500' }}
                                >
                                    {page.label}
                                </Button>
                            ))}
                        </Box> : null}
                    {logginStatus ?
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={(event) => setAnchorElUser(event.currentTarget)} sx={{ p: 0 }}>
                                    <Avatar alt={userName} src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem>
                                    <Typography fontFamily='Poppins' sx={{ fontSize: { xs: '10px', sm: "14px" } }} textAlign="left"><b>Name:</b> <br />{userName}</Typography>
                                </MenuItem>
                                <MenuItem>
                                    <Typography fontFamily='Poppins' sx={{ fontSize: { xs: '10px', sm: "14px" } }} textAlign="left"><b>Email:</b> <br />{userEmail}</Typography>
                                </MenuItem>
                                <hr />
                                <MenuItem onClick={() => { handleCloseUserMenu(); setShowPopup(true) }}>
                                    <Typography fontFamily='Poppins' sx={{ fontSize: { xs: '12px', sm: "16px" } }} textAlign="left">LOG OUT</Typography>
                                </MenuItem>
                            </Menu>
                        </Box> : null}
                </Toolbar>
            </Container>
            {
                showPopup ?
                    <Popup
                        message={'Are you sure you want to LOGOUT'}
                        LogOut={LogOut}
                        setShowPopup={setShowPopup}
                    /> : null
            }
        </AppBar >
    );
}
export default ResponsiveAppBar;