import React, { useState, createContext, useContext } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";

export const CompanyContext = createContext();
const CompanyProvider = ({ children }) => {
    const { authenticToken } = useContext(AuthContext)
    const [currentCompanyPage, setCurrentCompanyPage] = useState(1)
    const [companyTotalPages, setCompanyTotalPages] = useState(0);
    const [companyData, setCompanyData] = useState([]);
    const CompanyAPI = `${api}/companies`
    const CompanyAllData = async () => {
        try {
            const response = await fetch(CompanyAPI, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setCompanyData(data);
        } catch (err) {
            console.log('An Error Occurred at Company provider:', err);
        }
    }
    const CompanyAdded = async () => {
        try {
            const response = await fetch(CompanyAPI + `/paginate?page=${currentCompanyPage}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setCompanyTotalPages(data.totalPages)
            setCompanyData(data.data);
        } catch (err) {
            console.log('An Error Occurred at Company provider:', err);
        }
    }

    const CompanyState = {
        companyData,
        setCompanyData,
        CompanyAdded,
        CompanyAPI,
        currentCompanyPage,
        setCurrentCompanyPage,
        companyTotalPages,
        CompanyAllData

    }


    return (
        <CompanyContext.Provider value={CompanyState}>
            {children}
        </CompanyContext.Provider>
    )
}
export { CompanyProvider };