import React, { useState, createContext, useContext } from "react";
import { api } from "../API's";
import { AuthContext } from "./AuthProvider";


export const PlacesContext = createContext();
const PlacesProvider = ({ children }) => {
    const { authenticToken } = useContext(AuthContext)
    const PlaceAPI = `${api}/places`

    const [City, setCity] = useState([]);
    const month = [
        {
            label: 'Januaray',
            value: 1
        }, {
            label: 'Feburary',
            value: 2
        }, {
            label: 'March',
            value: 3
        }, {
            label: 'April',
            value: 4
        }, {
            label: 'May',
            value: 5
        }, {
            label: 'June',
            value: 6
        }, {
            label: 'July',
            value: 7
        }, {
            label: 'August',
            value: 8
        }, {
            label: 'September',
            value: 9
        }, {
            label: 'October',
            value: 10
        }, {
            label: 'November',
            value: 11
        }, {
            label: 'December',
            value: 12
        }
    ]
    const CityAdded = async () => {
        try {
            const response = await fetch(PlaceAPI, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticToken}` // Include the token in the Authorization header
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setCity(data);
        } catch (err) {
            console.log('An Error Occurred at Place provider:', err);
        }
    }

    return (
        <PlacesContext.Provider value={{ CityAdded, City, setCity, month, PlaceAPI }}>
            {children}
        </PlacesContext.Provider>
    )
}

export default PlacesProvider